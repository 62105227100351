import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import { SelectArrow } from '../../../../../constants/asset-contants';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState
} from '../../../../../recoil/atoms';
import { Alert } from '@material-ui/lab';
import { Grid } from '@mui/material';
import Input from '../../../../../components/new-dashboard/custom-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';
import CustomAutoComplete from '../../../../../components/new-dashboard/custom-autocomplete';
import { Box } from '@material-ui/core';
import {
  productOptions,
  shippingFromOptions
} from '../../../../../constants/shopnship-constants';
import { shopNShipLinks } from '../../../../../constants/shopnship-links';

export const SelectOptionsComponent = ({
  placeholder,
  selectedValue,
  setSelectedValue,
  optionsArray,
  label,
  setFieldValue,
  error = '',
  autocomplete = false
}) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const handleValueChange = country => {
    setSelectedValue(country);
    setToggleDropdown(false);
  };

  const toggleOptions = () => {
    setToggleDropdown(prevState => !prevState);
  };

  const autocompleteValue = useMemo(() => {
    if (!autocomplete || !selectedValue) {
      return null;
    }

    return optionsArray?.find(option => option?.value === selectedValue?.value);
  }, [autocomplete, selectedValue]);

  return autocomplete ? (
    <CustomAutoComplete
      options={optionsArray}
      getOptionLabel={option => option?.label}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderOption={(props, option, data) => (
        <Box
          {...props}
          // component='li'
          key={props.id}
          // className='d-flex align-items-center gap-2'
          sx={{
            '& > img': { mr: 2, flexShrink: 0 },
            display: 'flex',
            alignItems: 'center',
            paddingX: 16,
            paddingY: 24,
            gap: 8
          }}
        >
          {data && (
            <img
              loading='lazy'
              width='30'
              height='20'
              src={`https://flagcdn.com/w20/${
                option?.value === 'UK' ? 'gb' : option?.value?.toLowerCase()
              }.png`}
              srcSet={`https://flagcdn.com/w40/${
                option?.value === 'UK' ? 'gb' : option?.value?.toLowerCase()
              }.png 2x`}
              alt=''
            />
          )}
          {option?.label}
        </Box>
      )}
      value={autocompleteValue}
      noOptionsText={
        !autocompleteValue?.value
          ? 'Please enter your address'
          : 'Address not found'
      }
      onChange={(event, value, reason) => {
        if (reason === 'clear') {
          setFieldValue('shippingFrom', '');

          setSelectedValue({ label: '', value: '' });
        } else {
          setFieldValue('shippingFrom', value?.value);

          setSelectedValue(value);
        }
      }}
      textfieldProps={{
        lg: 12,
        md: 12,
        name: 'addressLine1',
        customLabel: label || 'Address',
        containerClassName: 'new-addresses__modal__body__form__item__input',
        value: autocompleteValue?.label,
        error: Boolean(error),
        helperText: error,
        onChange: event => {}
      }}
    />
  ) : (
    <>
      <div>
        <label htmlFor='destination'>{label}</label>
        <div className='select-wrap'>
          <div className='selected-option' onClick={toggleOptions}>
            <p
              style={{
                color: '#545859'
              }}
            >
              {selectedValue?.label || `Select ${placeholder}`}
            </p>
            <img src={SelectArrow} alt='select arrow' />
          </div>
          {toggleDropdown && (
            <div className='options'>
              {optionsArray.map((option, index) => {
                return (
                  <div
                    key={index}
                    className='option'
                    onClick={() => handleValueChange(option)}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ShopnshipWelcome = () => {
  const history = useHistory();
  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return filteredLinks.findIndex(
      link => link.link === history.location.pathname
    );
  }, [history.location.pathname]);

  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );

  const places = useMemo(() => {
    return {
      UK: `UK Hub: ${GetShopnshipHubAddress(
        'UK',
        shopNshipDetails?.pricingTier
      )}`,
      US: `USA Hub: ${GetShopnshipHubAddress(
        'US',
        shopNshipDetails?.pricingTier
      )}`,
      CANADA: `CANADA Hub: ${GetShopnshipHubAddress(
        'CA',
        shopNshipDetails?.pricingTier
      )}`,
      CHINA: 'CHINA'
    };
  }, [shopNshipDetails?.pricingTier]);

  const [selectedCountry, setSelectedCountry] = useRecoilState(
    shopnshipSelectedCountryState
  );

  // const origin = useMemo(() => {
  //   if (!selectedCountry || !standardOptions.includes(selectedCountry?.value)) {
  //     return '';
  //   }

  //   return selectedCountry?.origin;
  // }, [selectedCountry]);

  const [isChinaImport] = useRecoilState(chinaImportState);

  const [selectedProduct, setSelectedProduct] = useRecoilState(
    shopnshipSelectedProductState
  );

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .when('shippingFrom', {
        is: shippingFrom => shippingFrom === 'CHINA',
        then: schema => schema.required()
      })
      .label('Email Address'),
    phoneNumber: yup
      .string()
      .when('shippingFrom', {
        is: shippingFrom => shippingFrom === 'CHINA',
        then: schema => schema.required()
      })
      .label('Phone Number'),
    shippingFrom: yup.string().required('Where are you shipping from?')
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      email: '',
      phoneNumber: '',
      shippingFrom: ''
      // pricingTier: ''
    },

    onSubmit: values => {
      if (!isChinaImport) {
        setSelectedCountry(
          shippingFromOptions.find(
            option => option.value === values.shippingFrom
          )
        );
      }

      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });

      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    if (isChinaImport) {
      setFieldValue('shippingFrom', places.CHINA);
    } else {
      history.push(shopNShipLinks[1].link);
      // if (selectedCountry?.value) {
      //   setFieldValue('shippingFrom', selectedCountry?.value);
      // }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <NewShopNShipStepsLayout>
      <div className='new-shop-n-ship__modal__body'>
        <div className='new-shop-n-ship__modal__body__wrap'>
          <div className='new-shop-n-ship__modal__body__content'>
            {isChinaImport ? (
              <div className='new-shop-n-ship__modal__body__content__welcome-guide'>
                <h1 className='new-shop-n-ship__modal__body__content__welcome-guide__title mb-2'>
                  Import from <br /> China
                </h1>
                <p className='new-shop-n-ship__modal__body__content__welcome-guide__description'>
                  Receive goods from your suppliers and manufacturers in China.
                </p>

                {/* <div
                  style={{
                    border: '1px solid #ccc',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}
                  className='mb-5'
                >
                  <img
                    style={{
                      width: '90%',
                      height: 'auto',
                      margin: '0 auto'
                    }}
                    src='https://topship-web.s3.eu-west-2.amazonaws.com/adbanner4.webp'
                    alt=''
                  />
                </div> */}

                <form
                  className='mb-5 mt-5 new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
                  onSubmit={handleSubmit}
                >
                  <Grid container rowSpacing='16px' columnSpacing='10px'>
                    <Input
                      error={touched.email && Boolean(errors.email)}
                      helperText={errors.email}
                      value={values.email}
                      onChange={handleChange}
                      customLabel='Email address'
                      name='email'
                      id='email'
                      type='email'
                      containerClassName='new-shop-n-ship__modal__body__form__item__input'
                      placeholder='garrick@topship.com'
                      lg={12}
                      md={12}
                    />
                    <Input
                      id='phoneNumber'
                      name='phoneNumber'
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={errors.phoneNumber}
                      onChange={value => setFieldValue('phoneNumber', value)}
                      value={values.phoneNumber}
                      type='tel'
                      placeholder='+234 801 234 5678'
                      containerClassName='new-shop-n-ship__modal__body__form__item__input'
                      customLabel='Phone Number'
                      lg={12}
                      md={12}
                    />
                  </Grid>
                  <div className='faq__questions'>
                    <div className='faq__questions__wrap'></div>
                  </div>
                  <div className='new-shop-n-ship__modal__body__content__welcome-guide__buttons'>
                    {isChinaImport ? null : (
                      <Link to='/faq'>
                        <p className='mb-2'>See how it all works</p>
                      </Link>
                    )}

                    <SubmitButton text='Continue' />
                  </div>
                  <div
                    style={{
                      paddingBottom: '20px'
                    }}
                  />
                </form>
              </div>
            ) : (
              <div className='new-shop-n-ship__modal__body__content__welcome-guide'>
                <h1 className='new-shop-n-ship__modal__body__content__welcome-guide__title mb-2'>
                  Welcome to <br /> Shop & Ship
                </h1>
                <p className='new-shop-n-ship__modal__body__content__welcome-guide__description '>
                  Shop wherever, Ship whenever!
                </p>

                <form
                  className=' mb-5 new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
                  onSubmit={handleSubmit}
                >
                  <div className='address-container'>
                    {/* <div
                      style={{
                        border: '1px solid #ccc',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden'
                      }}
                      className='mb-5'
                    >
                      <img
                        style={{
                          width: '90%',
                          height: 'auto',
                          margin: '0 auto'
                        }}
                        src='https://topship-web.s3.eu-west-2.amazonaws.com/adbanner3.webp'
                        alt=''
                      />
                    </div> */}
                    <div>
                      <SelectOptionsComponent
                        setFieldValue={setFieldValue}
                        label={'Where are you shipping from?'}
                        placeholder={'a country'}
                        selectedValue={selectedCountry}
                        setSelectedValue={setSelectedCountry}
                        optionsArray={shippingFromOptions}
                        autocomplete
                      />
                      {/* {origin && (
                        <small
                          style={{
                            paddingTop: '0',
                            marginTop: '1em',
                            display: 'block'
                          }}
                        >
                          The sender's name should bear '[first name] [last
                          name] [identifier tag]
                          <strong> e.g Tayo Aluko - {origin}</strong> Please add
                          the identifier tag <strong>{origin}</strong> to your
                          name.
                        </small>
                      )} */}
                    </div>
                    <div className='mt-4'>
                      <SelectOptionsComponent
                        label={'What type of product are you purchasing?'}
                        placeholder={'product'}
                        selectedValue={selectedProduct}
                        setSelectedValue={setSelectedProduct}
                        optionsArray={productOptions}
                      />
                    </div>

                    <div className='mt-3'>
                      {isChinaImport && (
                        <Alert
                          severity={'info'}
                          sx={{
                            fontSize: '1.5rem',
                            fontFamily: 'General Sans',
                            marginTop: '10px',
                            backgroundColor: '#e6f2fe',
                            color: '#000000',
                            border: 'none',
                            '& .MuiAlert-icon': {
                              color: '#22428F'
                            }
                          }}
                        >
                          Note that the address for drop off would be made
                          available after payment has been made
                        </Alert>
                      )}
                    </div>
                  </div>
                  <div className='faq__questions'>
                    <div className='faq__questions__wrap'></div>
                  </div>
                  <div className='new-shop-n-ship__modal__body__content__welcome-guide__buttons'>
                    <Link to='/faq'>
                      <p className='mb-2'>See how it all works</p>
                    </Link>
                    <SubmitButton text='Continue' />
                  </div>
                  <div
                    style={{
                      paddingBottom: '20px'
                    }}
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </NewShopNShipStepsLayout>
  );
};

export default ShopnshipWelcome;
