import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';
import AlertTemplate from 'react-alert-template-basic';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import Login from './pages/Authentication/Login/login.index';
import Signup from './pages/Authentication/Signup/signup';
import ForgotPassword from './pages/Authentication/PasswordReset/forgot-password';
import VerifyMail from './pages/Authentication/PasswordReset/verify-mail';
import ResetPassword from './pages/Authentication/PasswordReset/reset-password';
import Dashboard from './pages/Dashboard/dashboard.index';
// import TrackingCode from './pages/Dashboard/Tracking/tracking-code';
// import TrackingDetails from './pages/Dashboard/Tracking/tracking-details';
import PastShipments from './pages/Dashboard/PastShipments/past-shipment-list';
import NotFound from './pages/NotFound/notfound';
import { ProtectedRoute } from './config/protected-route';
import BusyOverlay from './components/busy-overlay';
import { usePageViews } from './utilities/usePageViews';
import FeedbackSuccess from './pages/Dashboard/Feedback/feedback-success';
import TermsAndConditions from './pages/TermsAndConditions/terms-and-conditions';
import SenderInfo from '../src/pages/Dashboard/Requests/Shipment/sender-info';
import './App.css';
import SignupSuccessful from './pages/Authentication/Signup/signup-sucessful';
import WalletFundingSuccessful from './pages/Dashboard/Wallet/FundWallet/wallet-funding-successful';
import ShopNShipSuccessful from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-successful';
import GetQuotes from './pages/Dashboard/Requests/Quotes/get-quotes';
import NewWallet from './pages/Dashboard/Wallet/new-wallet.index';
import Faq from './pages/Dashboard/FAQs/faq.index';
import NewReferral from './pages/Dashboard/Referral/new-referral.index';
import NewAddresses from './pages/Dashboard/Addresses/new-addresses';
import ReceiverInfo from './pages/Dashboard/Requests/Shipment/receiver-info';
import ItemDescription from './pages/Dashboard/Requests/Shipment/item-description';
import DeliveryOptions from './pages/Dashboard/Requests/Shipment/delivery-options';
import OrderSummary from './pages/Dashboard/Requests/Shipment/order-summary';
import MultipleReceiverInfo from './pages/Dashboard/Requests/Shipment/multiple-receiver-info';
import NewViewQuote from './pages/Dashboard/Requests/Quotes/new-view-quote';
import { useLocation } from 'react-router-dom';

import {
  multipleShipmentLinks,
  seaFreightImportLinks,
  singleShipmentLinks
} from './constants/shipment-links';
import MultipleDeliveryOptions from './pages/Dashboard/Requests/Shipment/multiple-delivery-options';
import ShopNShipPersonalInformation from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-personal-information';
import ShopNShipItemInformation from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-item-information';
import ShopNShipOtherInformation from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-other-information';
import MultipleShipmentPayment from './pages/Dashboard/Requests/Shipment/Payment/multiple-shipment-payment';
import useClearLocalStorage from './utilities/clear-localstorage';
import { shopNShipLinks } from './constants/shopnship-links';
import PaymentSuccessfull from './components/Commons/success-page';
import PastShopnShips from './pages/Dashboard/PastShopnships/past-shopnship-list';
import ShopnshipPackageInformation from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-package-information';
import Profile from './pages/Dashboard/Profile/profile.index';
// import VIB from './pages/Dashboard/VIB/vib.index';
import ShopnShipSummary from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-summary';
import ShopnshipDeliveryOption from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-delivery-information';
import Settings from './pages/Dashboard/Settings/settings.index';
import { useAuthToken } from './token';
import { useGetUserLazyQuery } from './operations/queries';
import { accountType } from './constants/authentication';
import { getNewUserDate } from './utilities/get-current-date';
import AdditionalCharges from './pages/Dashboard/AdditionalCharges/additional-charge';
import ShopnshipWelcome from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-welcome';
import GuidesAndResourcesPage from './pages/Guides&Resources/resources';
import ForeignExchangePayment from './pages/Dashboard/Requests/Shipment/Payment/foreign-exchange-payment';
import ShopnshipDeliveryAddress from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-delivery-address';
import PastChinaImports from './pages/Dashboard/PastChinaImports/past-china-imports';
import NewTrackingModal from './components/modals/new-tracking-modal';
import ShopnshipShippingInformation from './pages/Dashboard/Requests/Shipment/BlackFriday/shop-n-ship-shipping-information';
import MultipleShopNShipPayment from './pages/Dashboard/Requests/Shipment/Payment/multiple-shop-n-ship-payment';
import { pathWithoutQuery } from './utilities/path-without-query';
import SeaFreightWelcome from './pages/Dashboard/Requests/Shipment/SeaFreight/sea-freight-welcome';
import SeaFreightSenderInfo from './pages/Dashboard/Requests/Shipment/SeaFreight/sea-freight-sender-info';
import SeaFreightItemDescription from './pages/Dashboard/Requests/Shipment/SeaFreight/sea-freight-item-description';
import SeaFreightReceiverInfo from './pages/Dashboard/Requests/Shipment/SeaFreight/sea-freight-receiver-info';
import SeaFreightPackageUpload from './pages/Dashboard/Requests/Shipment/SeaFreight/sea-freight-package-upload';
import OurServices from './pages/Dashboard/Services/services.index';

// Create a client
const queryClient = new QueryClient();
function App() {
  const options = {
    position: positions.TOP_RIGHT,
    timeout: 10000,
    offset: '20px',
    // you can also just use 'scale's
    transition: transitions.SCALE
  };
  const { pathname } = useLocation();
  const history = useHistory();
  const clearStorage = useClearLocalStorage();
  const allShipmentLinks = [
    ...singleShipmentLinks,
    ...multipleShipmentLinks,
    ...shopNShipLinks,
    ...seaFreightImportLinks,
    { link: '/login' },
    { link: '/signup' },
    { link: '/request-quote' },
    { link: '/viewquote' },
    { link: '/shipment-request-foreign-exchange-payment' },
    { link: '/shop-and-ship-multiple-payment' },
    {
      link: '/shop-and-ship-payment'
    },
    { link: '/shop-and-ship-multiple-payment' }
  ];

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const businessId = localStorage.getItem('lspId');
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const [getUser, { data }] = useGetUserLazyQuery(undefined, undefined, true);
  const [openTrackingModal, setOpenTrackingModal] = useState(true);
  const trackingId = pathname.split('/').pop();

  window.pendo.initialize({
    visitor: {
      id: `${data?.user?.id}`,
      email: `${data?.user?.email}`
    },

    account: {
      id: `${data?.user?.topshipId}`,
      name: `${data?.user?.fullName}`,
      createdDate: `${new Date(data?.user?.createdDate)}`,
      accountType: `${data?.user?.accountType}`
    }
  });
  useLayoutEffect(() => {
    async function awaitUser() {
      if (isLoggedIn) {
        await getUser();
      }
    }
    awaitUser();

    //eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    // const justPaid = localStorage.getItem('justPaid');
    if (
      !allShipmentLinks.find(link => link.link === pathWithoutQuery(pathname))
    ) {
      // if (!justPaid) {
      // }
      clearStorage();
    }
    window.scroll(0, 0);

    if (
      !pathname.includes('signup') &&
      isLoggedIn &&
      !pathname.includes('login')
    ) {
      if (data && data?.user) {
        if (businessId !== 'null' && businessId !== null) {
          searchParams.set('lsp', businessId);
        } else {
          searchParams.delete('lsp');
        }

        if (getNewUserDate(data?.user?.createdDate)) {
          if (data?.user?.accountType === accountType[2].value) {
            searchParams.set('isLsp', true);
          } else {
            searchParams.delete('isLsp');
          }
        }
      }
    }
    history.push({
      search: searchParams?.toString()
    });
    // eslint-disable-next-line
  }, [searchParams, pathname, data]);

  useEffect(() => {
    if (isLoggedIn && data) {
      if (window.OneSignal) {
        window.OneSignal.push(function () {
          window.OneSignal.setExternalUserId(data?.user?.topshipId);
        });
      }
    }
  }, [data, isLoggedIn]);

  useEffect(() => {
    if (
      window.location.pathname === '/tracking' ||
      window.location.pathname === `/tracking/details/${trackingId}`
    ) {
      setOpenTrackingModal(true);
    }
  }, [trackingId]);

  usePageViews();

  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Fragment>
            <Switch>
              <Route exact path='/terms' component={TermsAndConditions}></Route>
              <Route
                exact
                path='/policy'
                component={TermsAndConditions}
              ></Route>
              <Route exact path='/login' component={Login}></Route>
              <Route exact path='/signup' component={Signup}></Route>
              <Route
                exact
                path='/signup-successful'
                component={SignupSuccessful}
              ></Route>
              <Route exact path='/ref/:topshipId' component={Signup}></Route>
              <Route exact path='/verify-mail' component={VerifyMail}></Route>
              <Route
                exact
                path='/password-reset/:token'
                component={ResetPassword}
              ></Route>
              <Route
                exact
                path='/forgot-password'
                component={ForgotPassword}
              ></Route>
              <Route exact path='/viewquote' component={NewViewQuote} />
              <ProtectedRoute
                exact
                path='/'
                component={Dashboard}
              ></ProtectedRoute>
              <Route
                exact
                path='/tracking'
                render={() => (
                  <NewTrackingModal
                    openModal={openTrackingModal}
                    setOpenModal={setOpenTrackingModal}
                  />
                )}
              ></Route>
              <Route
                exact
                path='/tracking/details/:id'
                // component={TrackingDetails}
                render={() => (
                  <NewTrackingModal
                    trackingId={trackingId}
                    openModal={openTrackingModal}
                    setOpenModal={setOpenTrackingModal}
                  />
                )}
              ></Route>
              <ProtectedRoute
                exact
                path='/wallet'
                component={NewWallet}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/services'
                component={OurServices}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/pending-charges'
                component={AdditionalCharges}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/wallet/wallet-funding-successful'
                component={WalletFundingSuccessful}
              ></ProtectedRoute>
              <Route
                exact
                path='/shipment-request-sender-information'
                component={SenderInfo}
              ></Route>
              <Route
                exact
                path='/multiple-shipment-request-sender-information'
                component={SenderInfo}
              ></Route>
              <Route
                exact
                path='/shipment-request-receiver-information'
                component={ReceiverInfo}
              ></Route>
              <Route
                exact
                path='/multiple-shipment-request-receiver-information'
                component={MultipleReceiverInfo}
              ></Route>
              <Route
                exact
                path='/shipment-request-item-description'
                component={ItemDescription}
              ></Route>
              <Route
                exact
                path='/shipment-request-delivery-options'
                component={DeliveryOptions}
              ></Route>
              <Route
                exact
                path='/multiple-shipment-request-delivery-options'
                component={MultipleDeliveryOptions}
              ></Route>
              <Route
                exact
                path='/shipment-request-order-summary'
                component={OrderSummary}
              ></Route>
              <Route
                exact
                path='/shipment-request-payment'
                component={OrderSummary}
              ></Route>
              <Route
                exact
                path='/shipment-request-foreign-exchange-payment'
                component={ForeignExchangePayment}
              ></Route>
              <Route
                exact
                path='/multiple-shipment-request-order-summary'
                component={OrderSummary}
              ></Route>
              <Route
                exact
                path='/multiple-shipment-request-payment'
                component={MultipleShipmentPayment}
              ></Route>
              <Route
                exact
                path='/shop-and-ship-multiple-payment'
                component={MultipleShopNShipPayment}
              ></Route>
              <Route
                exact
                path='/shipment-payment-successful'
                component={PaymentSuccessfull}
              ></Route>

              <ProtectedRoute
                exact
                path='/past-shipments'
                component={PastShipments}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/past-shopnships'
                component={PastShopnShips}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/past-china-imports'
                component={PastChinaImports}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/addresses'
                component={NewAddresses}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-welcome'
                component={ShopnshipWelcome}
              ></ProtectedRoute>

              <ProtectedRoute
                exact
                path='/shop-and-ship-package-information'
                component={ShopnshipPackageInformation}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-item-information'
                component={ShopNShipItemInformation}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-shipping-information'
                component={ShopnshipShippingInformation}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-other-information'
                component={ShopNShipOtherInformation}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-delivery-option'
                component={ShopnshipDeliveryOption}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-delivery-address'
                component={ShopnshipDeliveryAddress}
              ></ProtectedRoute>

              <ProtectedRoute
                exact
                path='/shop-and-ship-payment'
                component={ShopnShipSummary}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-summary'
                component={ShopnShipSummary}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-welcome'
                component={SeaFreightWelcome}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-sender-information'
                component={SeaFreightSenderInfo}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-item-description'
                component={SeaFreightItemDescription}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-receiver-information'
                component={SeaFreightReceiverInfo}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-package-upload'
                component={SeaFreightPackageUpload}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/sea-freight-quote-successful'
                component={ShopNShipSuccessful}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/shop-and-ship-successful'
                component={ShopNShipSuccessful}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/feedback-received'
                component={FeedbackSuccess}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/referral'
                component={NewReferral}
              ></ProtectedRoute>
              <Route exact path='/request-quote' component={GetQuotes}></Route>
              <ProtectedRoute
                exact
                path='/faq'
                component={Faq}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/profile'
                component={Profile}
              ></ProtectedRoute>
              {/* <ProtectedRoute
                exact
                path='/vib'
                component={VIB}
              ></ProtectedRoute> */}
              <ProtectedRoute
                exact
                path='/settings'
                component={Settings}
              ></ProtectedRoute>
              <ProtectedRoute
                exact
                path='/guides-and-resources'
                component={GuidesAndResourcesPage}
              ></ProtectedRoute>
              <Route render={props => <NotFound heading='Page not found' />} />
            </Switch>
          </Fragment>
        </AlertProvider>
        <BusyOverlay />
      </QueryClientProvider>
    </div>
  );
}

export default App;
