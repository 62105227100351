import {
  CalendarIcon,
  Estimator,
  MinWeightIcon,
  TruckIcon
} from './asset-contants';

const shippingRates = [
  {
    name: 'Express',
    pickupDeliveryTime: 'Delivery in 3-5 working days',
    cost: '₦50,000',
    value: 'Express',
    amount: 50000,
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Standard',
    // pickupLeadTime: ' 24-48hrs',
    pickupDeliveryTime: 'Delivery in 5-7 working days',
    cost: '₦20,850',
    value: 'Standard',
    amount: 20850
  },
  {
    name: 'Cargo',
    // pickupLeadTime: ' 12-24hrs',
    pickupDeliveryTime: 'Cargo delivery in 7-14 working days',
    cost: '₦50,000',
    value: 'Budget',
    amount: 50000,
    tags: [
      `No Doorstep Delivery`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },

  {
    name: 'Budget',
    // pickupLeadTime: ' 12-24hrs',
    pickupDeliveryTime: 'Cargo delivery in 7-14 working days',
    cost: '₦50,000',
    value: 'LastMileBudget',
    budgetOptionWithExpress: 'Ship to Lagos Hub with Express',
    budgetOptionFree: 'Ship to Lagos Hub by yourself',
    amount: 50000,
    tags: [
      `Doorstep Delivery`,
      `Customs clearance charge is included in price`,
      `Receiver's Signature Required`,
      'Item will be shipped in a carton box, which may affect the final weight. Please review the weight, as volumetric weight may apply'
      // `Requires 3Kg or more`
    ]
  },
  {
    name: 'Saver',
    value: 'UPSExpedited',
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Saver',
    value: 'UPSEconomy',
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Saver',
    value: 'UPSExpress',
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Saver',
    value: 'FedEx',
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Premium',
    value: 'Premium',
    tags: [`Order on or before 11am`, `2 Working Days for Late Bookings`]
  },
  {
    name: 'Regular',
    value: 'Regular'
  },
  {
    name: 'Saver Priority',
    pickupDeliveryTime: 'Delivery in 10-12 working days',
    cost: '₦20,850',
    value: 'SaverPriority',
    amount: 20850,
    tags: [
      `Doorstep Delivery`,
      `Receiver's Signature Required`,
      `Receiver will be required to pay customs clearance charges`
    ]
  },
  {
    name: 'Saver Domestic',
    value: 'GIG',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    amount: 50000,
    tags: [`Doorstep Delivery`, `Receiver's Signature Required`]
  },
  {
    name: 'Ship Via Sea',
    pickupDeliveryTime: 'Delivery in 8 - 12 weeks',
    cost: '₦20,850',
    value: 'SeaExport',
    pricingTier: 'SeaExport',
    amount: 20850,
    tagsWithIcons: [
      {
        text:
          'Customs Clearance Fee: Subject to shipment weight and warehouse pick-up location',
        icon: Estimator
      },
      {
        text: 'Doorstep delivery attracts additional fee',
        icon: TruckIcon
      },
      {
        text: 'Shipping is done once a month',
        icon: CalendarIcon
      },
      {
        text: 'Minimum weight is 100KG',
        icon: MinWeightIcon
      }
    ]
  },
  {
    name: 'Sea Import',
    pickupDeliveryTime: 'Delivery in 8 - 12 weeks',
    cost: '₦20,850',
    value: 'SeaImport',
    amount: 20850,
    tags: []
  }
];

export const pickupOptions = [
  {
    value: 'Fez',
    pickupDeliveryTime:
      'Book before 12pm for same-day pick-up, next-day delivery'
  },
  {
    value: 'Dellyman',
    pickupDeliveryTime: 'Same-day pick-up and delivery'
  },
  {
    value: 'Sendstack',
    pickupDeliveryTime: 'Next-day pick-up and delivery'
  },
  {
    value: 'Messenger',
    pickupDeliveryTime: 'Same-day pick-up and delivery'
  },
  {
    value: 'Standard',
    pickupDeliveryTime: '1 - 2 working days'
  }
];

export const statesWithTopshipHubs = ['Lagos', 'Abuja', 'Oyo', 'Rivers'];

const lastMileShippingRates = [
  {
    name: 'Saver Domestic',
    value: 'GIG',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    amount: 50000
  },
  {
    name: 'DHL',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    value: 'Express',
    amount: 50000
  }
];

export const lightColors = [
  '#edf5ff45',
  '#eaf4fc3b',
  '#e6f7ff3b',
  '#e0e8f054',
  '#aec6cf29'
];

const shopnshipRates = [
  {
    name: 'Express',
    pickupDeliveryTime: 'Delivery in 3-7 working days',
    cost: '₦50,000',
    value: 'Express',
    amount: 50000,
    tags: [`Receiver will be required to pay customs clearance charges`]
  },
  {
    name: 'Standard',
    // pickupLeadTime: ' 24-48hrs',
    pickupDeliveryTime: 'Delivery in 5-7 working days',
    cost: '₦20,850',
    value: 'Standard',
    amount: 20850,
    tags: [`Customs clearance charge is included in price`]
  }
];

export { shopnshipRates, shippingRates, lastMileShippingRates };
