import { ReactComponent as InfoIcon } from '../assets/svg/info-icon-two.svg';
import { ReactComponent as GoIcon } from '../assets/svg/go-icon.svg';
import { useHistory } from 'react-router-dom';

const CompleteOrderTracking = () => {
  const history = useHistory();

  return (
    <div
      className='complete__order-container'
      onClick={() => history.push('/past-shopnships')}
    >
      <div className='complete__order-content'>
        <InfoIcon />
        <p className='complete__order-tracking--text'>
          Enter Order Tracking Number to Complete Order
        </p>
        <GoIcon />
      </div>
    </div>
  );
};

export default CompleteOrderTracking;
