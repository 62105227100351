import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import Input from '../new-dashboard/custom-input';
import useClearLocalStorage from '../../utilities/clear-localstorage';
import { useRecoilState } from 'recoil';
import { shopNShipResultState } from '../../recoil/atoms';
import { toCurrency } from '../../utilities/to-currency';
import { useUpdateShopnshipMutation } from '../../operations/mutations';
import {
  useGetShopnshipQuery,
  useGetShopnshipsQuery
} from '../../operations/queries';
import BusyOverlay from '../../components/busy-overlay';
import SubmitButton from '../submit-button';
import { useSendAlert } from '../../utilities/send-alert';

const TrackingIdModal = ({ setShowTrackingModal, shipmentId }) => {
  const sendAlert = useSendAlert();

  const [shopNShipDetails, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );

  const getShopnshipsQuery = useGetShopnshipsQuery();

  const [shipment, setShipment] = useState();

  const [getShopnship, getShopnshipResult] = useGetShopnshipQuery(data => {
    setShopNShipResult(data?.getShopnship);
    setShipment(data?.getShopnship);
  });
  const [firstView, setFirstView] = useState();

  const [items, setItems] = useState([]);

  const [updateShopnship, { loading }] = useUpdateShopnshipMutation(data => {
    if (data) {
      sendAlert('Tracking number(s) updated.');
      getShopnshipsQuery();
      // setTimeout(() => {
      setShowTrackingModal(false);
      // }, 3000);
    }
  });

  const validationSchema = yup.object({
    items: yup.array().of(
      yup.object({
        trackingNumber: yup.string().required('Please enter tracking number')
      })
    )
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      items: items.map(item => ({
        ...item,
        trackingNumber: item.trackingNumber || ''
      }))
    },
    validationSchema,
    onSubmit: () => {
      const newItems = JSON.stringify(values.items);
      updateShopnship({
        id: shopNShipDetails.id,
        update: { itemDescription: newItems }
      });
    }
  });

  useEffect(() => {
    getShopnship(shipmentId);
    if (shipment) {
      const parsedItems = JSON.parse(shipment?.itemDescription);

      setItems(parsedItems);
    }
    // if (Object.keys(shopNShipDetails).length === 0 || !shopNShipDetails) {
    // } else {
    //   const paymentSuccessful = localStorage.getItem('justPaid');
    //   setFirstView(paymentSuccessful);
    //   const parsedItems = JSON.parse(shopNShipDetails.itemDescription);
    //   setItems(parsedItems);
    // }
  }, [shipment]);

  const handleCancel = () => {
    setShowTrackingModal(false);
    // clearStorage(() => {
    // });
  };

  return (
    <>
      <BusyOverlay loading={getShopnshipResult.loading || loading} />
      <div className='tracking__id__modal'>
        <div className='tracking__id__modal-content'>
          <div className='heading__text-container'>
            {firstView ? (
              <p className='heading__text'>One Last Thing</p>
            ) : (
              <p className='heading__text'>Order Tracking Number</p>
            )}
            <Close
              background='blue'
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
            />
          </div>
          {firstView ? (
            <div className='heading__texts'>
              <p className='heading__sub__text-main'>
                Help us track your package
              </p>
              <p className='heading__sub__text-sub'>
                Enter your order tracking number below
              </p>
            </div>
          ) : (
            ''
          )}
          <form className='tracking__modal__items' onSubmit={handleSubmit}>
            {values.items.map((item, index) => (
              <div className='tracking__modal__item' key={item.id}>
                <p className='modal__item__heading'>Item {index + 1}</p>
                <div className='modal__item__boxes'>
                  <div className='modal__item__box'>
                    <div className='item__texts__box'>
                      <p className='item__box__label__text'>ITEM CATEGORY</p>
                      <p className='item__box__value__text'>{item.category}</p>
                    </div>
                    <div className='item__texts__box'>
                      <p className='item__box__label__text'>ITEM VALUE</p>
                      <p className='item__box__value__text'>
                        {toCurrency(item.amountDue)}
                      </p>
                    </div>
                  </div>
                  <div className='modal__item__box'>
                    <div className='item__texts__box'>
                      <p className='item__box__label__text'>QUANTITY</p>
                      <p className='item__box__value__text'>{item.quantity}</p>
                    </div>
                    <div className='item__texts__box'>
                      <p className='item__box__label__text'>WEIGHT</p>
                      <p className='item__box__value__text'>
                        {item.estimatedWeight} KG
                      </p>
                    </div>
                  </div>
                </div>
                <div action='' className='modal__item__form'>
                  <label
                    htmlFor={`items.${index}.trackingNumber`}
                    className='item__form__label'
                  >
                    Order Tracking Number (from the retailer)
                  </label>
                  <Input
                    name={`items.${index}.trackingNumber`}
                    id={`items.${index}.trackingNumber`}
                    value={values.items[index].trackingNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    placeholder='#000000000000'
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                  />
                  {touched.items &&
                    touched.items[index] &&
                    errors.items &&
                    errors.items[index] &&
                    errors.items[index].trackingNumber && (
                      <div className='tracking__number__modal__error'>
                        {errors.items[index].trackingNumber}
                      </div>
                    )}
                </div>
              </div>
            ))}
            <div className='modal__ctas'>
              <button
                className='modal__cta modal__cancel__cta'
                type='button'
                onClick={handleCancel}
              >
                Cancel
              </button>
              <SubmitButton
                loading={loading}
                className='modal__cta modal__complete__cta'
                type='submit'
                disabled={loading}
                text={'Complete Order'}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TrackingIdModal;
