export function GetShopnshipHubAddress(country, pricingTier) {
  switch (country) {
    case 'US':
      return pricingTier === 'Express'
        ? '4297 Express Lane, Sarasota, FL 34249, Sarasota Florida, 34249-2602 TEL: 941 326 2501'
        : '172-30 Baisley Blvd Jamaica, NY 11434';

    case 'UK':
      return pricingTier === 'Express'
        ? 'Aramex UK, SNS Unit 9 Skyport Drive West Drayton Harmondsworth UB7 0LB TEL: 01753-210399'
        : '164 honeypot lane, Unit 1107, Stanmore HA7 1EE';

    case 'CA':
      return pricingTier === 'Express'
        ? '5810 Ambler Drive, Unit 14, Mississauga, Ontario, L4W 4J5 TEL: 001 (905) 238-0440'
        : '32 Pemican Ct, North York, Toronto, Ontario M9M2L4';

    case 'CHINA':
      return 'CHINA';

    default:
      return '';
  }
}
