import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import SubmitButton from '../../../../../components/submit-button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  shopNShipDetailsState,
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  selectedRateState,
  saveLastMileState,
  selectedLastMileDeliveryOption
} from '../../../../../recoil/atoms';
import BusyOverlay from '../../../../../components/busy-overlay';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import { useHistory } from 'react-router-dom';
import { useSendAlert } from '../../../../../utilities/send-alert';
import testJSON from '../../../../../utilities/test-json';
import {
  useGetItemCategoriesQuery,
  useGetLastMileRatesQuery,
  useGetShopNShipRateQuery,
  useGetShopnshipCurrencyRates
} from '../../../../../operations/queries';
import insurancePlans from '../../../../../constants/insurance-plans';
import {
  WhiteCheck,
  SelectArrowBlue
} from '../../../../../constants/asset-contants';
import {
  DeliveryOptions1,
  DeliveryOptions2,
  InsuranceOptions
} from '../../../../../components/shipping-options';
import { toFixed } from '../../../../../utilities/to-fixed';
import shipmentRateModeDictionary from '../../../../../constants/shipment-rate-modes-dictionary';
import { pathWithoutQuery } from '../../../../../utilities/path-without-query';

const ShopNShipOtherInformation = () => {
  const [shopNShipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );
  const [isChinaImport] = useRecoilState(chinaImportState);

  const isArray = testJSON(shopNShipDetails?.itemDescription);

  const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery({});

  const shopNShipRates = useRecoilValue(saveShopNShipRateState);

  const [lastMileRates] = useRecoilState(saveLastMileState);

  const [getShopnshipRates] = useGetShopnshipCurrencyRates();

  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);

  const activeRate = shopNShipRates.filter(
    rate => rate.pricingTier === shopNShipDetails?.pricingTier
  );

  const [selectedLastMile, setSelectedLastMile] = useRecoilState(
    selectedLastMileDeliveryOption
  );

  const [
    getLastMileRate,
    { data: lastMileData, loading: lastMileLoading }
  ] = useGetLastMileRatesQuery({ fetchPolicy: 'no-cache' });

  const [openToggle, setOpenToggle] = useState({
    delivery: true,
    insurance: true,
    lastMile: true
  });

  const totalItemValue =
    (isArray
      ? JSON.parse(shopNShipDetails?.itemDescription)?.reduce(
          (sum, item) => sum + item?.amountDue,
          0
        )
      : shopNShipDetails?.amountDue) || 0;

  const isImportBooking = useMemo(() => {
    if (!shopNShipDetails) {
      return false;
    }

    return (
      !shopNShipDetails?.id &&
      shopNShipDetails?.deliveryState &&
      shopNShipDetails?.deliveryState?.toLowerCase() !== 'lagos'
    );
  }, [shopNShipDetails]);

  const onCompleted = data => {
    const items = JSON.parse(shopNShipDetails?.itemDescription);

    // we are joining all tracking numbers for backward compatibility (old shopnship did not have multiple items)
    const trackingNumber = isArray
      ? items?.map(item => item?.trackingNumber)?.join()
      : shopNShipDetails?.trackingNumber;

    // we are joining all proofs of id for backward compatibility (old shopnship did not have multiple items)
    const proofOfId = isArray
      ? items.map(item => item?.proofOfId)?.join()
      : shopNShipDetails?.proofOfId;

    // we are joining all evidences of purchase for backward compatibility (old shopnship did not have multiple items)
    const evidenceOfPurchase = isArray
      ? items.map(item => item?.evidenceOfPurchase)?.join()
      : shopNShipDetails?.evidenceOfPurchase;

    setSaveShopNshipDetails({
      ...shopNShipDetails,
      ...values,
      proofOfId,
      trackingNumber,
      evidenceOfPurchase,
      ...(isImportBooking
        ? {
            lastMileCharge: toFixed(selectedLastMile?.cost),
            lastMilePricingTier: shipmentRateModeDictionary(
              selectedLastMile?.pricingTier
            )
          }
        : {}),
      ...(!isChinaImport && {
        initialCharge: activeRate?.[0]?.cost,
        foreignExchangeCost: activeRate?.[0]?.foreignExchangeCost,
        foreignExchangeRate: activeRate?.[0]?.foreignExchangeRate
      })
    });
    if (!!shopNShipDetails?.id) {
      history.push(
        `${filteredLinks[currentIndex + 1].link}/?shopnshipID=${
          shopNShipDetails?.id
        }`
      );
    } else {
      history.push(filteredLinks[currentIndex + 1].link);
    }
  };

  const history = useHistory();

  const sendAlert = useSendAlert();

  const [getItemsCategories] = useGetItemCategoriesQuery();

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const currentIndex = filteredLinks.findIndex(
    link => link.link === pathWithoutQuery(history.location.pathname)
  );

  const handleLastMileSelection = rate => {
    setSelectedLastMile(rate);
  };

  const [isChecked, setIsChecked] = useState(false);

  const validationSchema = yup.object().shape({
    isChinaImport: yup.boolean().required(),
    receiverPaymentDetails: yup.boolean().when('isChinaImport', {
      is: false,
      then: yup.boolean().required('Please select an option'),
      otherwise: yup.boolean()
    }),
    uploadingMyDocuments: yup.boolean().when('isChinaImport', {
      is: false,
      then: yup.boolean().required('Please select an option'),
      otherwise: yup.boolean()
    })
  });

  const { values, touched, errors, handleSubmit, setFieldValue } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      isChinaImport: isChinaImport,
      insuranceType: '',
      insuranceCharge: 0,
      uploadingMyDocuments: true,
      receiverPaymentDetails: true
    },

    onSubmit: values => {
      if (!values.insuranceType) {
        sendAlert('Please select your Insurance Type', 'error');
        window.scroll(0, 0);
      } else {
        delete values.isChinaImport;

        onCompleted();
      }
    }
  });

  const handleStateChange = (name, value) => {
    setFieldValue(name, value);
  };

  const handleRateSelection = rate => {
    setSelectedRate(rate);
    setFieldValue('pricingTier', rate?.pricingTier);
  };

  const handleInsuranceSelection = plan => {
    const insuranceCharge =
      plan.value === insurancePlans[2].value
        ? totalItemValue * 0.05
        : plan.amount;

    setFieldValue('insuranceType', plan.value);
    setFieldValue('insuranceCharge', insuranceCharge);
  };

  useEffect(() => {
    if (shopNShipDetails.id) {
      getItemsCategories({
        isChinaImport: true,
        pricingTier: selectedRate?.pricingTier || 'Standard'
      });
    }
  }, [shopNShipDetails]);

  useEffect(() => {
    if (shopNShipRates) {
      const activeRates = shopNShipRates.filter(
        rate => rate.pricingTier === shopNShipDetails?.pricingTier
      );
      setSelectedRate(activeRates[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNShipRates]);

  useEffect(() => {
    if (shopNShipDetails) {
      const items = JSON.parse(shopNShipDetails?.itemDescription);

      const requestRatesData = items?.map(item => {
        return { weight: item?.estimatedWeight, category: item?.category };
      });

      if (!isChinaImport) {
        getShopNshipRates({
          items: requestRatesData,
          shippingFrom: shopNShipDetails?.shippingFrom
        });
      } else {
        getShopNshipRates({ isChinaImport: true, items: requestRatesData });
      }

      let totalValue = 0;

      items?.forEach(item => {
        if (item.isVolumetric) {
          const volumetricWeight = Number(
            ((item.width * item.height * item.length) / 5000).toFixed(2)
          );
          totalValue += volumetricWeight;
        } else {
          totalValue += item.weight || item.estimatedWeight;
        }
      });

      if (isImportBooking) {
        getLastMileRate({
          receiverDetail: {
            name: shopNShipDetails?.deliveryName,
            email: shopNShipDetails?.email,
            phoneNumber: shopNShipDetails?.phoneNumber,
            addressLine1: shopNShipDetails?.deliveryAddress,
            city: shopNShipDetails?.deliveryCity || '',
            country: 'Nigeria',
            countryCode: 'NG',
            state: shopNShipDetails?.deliveryState || 'Lagos'
          },
          totalWeight: parseFloat(totalValue, 10)
        });
      }

      if (!!shopNShipDetails?.id) {
        setFieldValue('insuranceType', shopNShipDetails.insuranceType);
        setFieldValue(
          'insuranceCharge',
          shopNShipDetails.insuranceCharge / 100
        );
        setIsChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNShipDetails, isChinaImport, isImportBooking]);

  useEffect(() => {
    if (isChinaImport) {
      if (selectedRate?.cost) {
        const params = {
          shippingFrom: 'CHINA',
          chinaCost: selectedRate?.cost
        };

        getShopnshipRates(params);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRate?.cost, shopNShipDetails?.shippingFrom, isChinaImport]);

  useEffect(() => {
    if (!lastMileLoading && lastMileData) {
      const defaultOption = lastMileData?.getLastMileRates?.[0];
      if (defaultOption) {
        setSelectedLastMile(defaultOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMileLoading, lastMileData, selectedLastMile]);

  return (
    <NewShopNShipStepsLayout mobileTitle='Other Information'>
      <div className='new-shop-n-ship__modal__body'>
        <div className='new-shop-n-ship__modal__body__wrap'>
          <div className='new-shop-n-ship__modal__body__content'>
            <form
              onSubmit={handleSubmit}
              className='new-shop-n-ship__modal__body__form'
            >
              <div className='delivery-options mt-5'>
                <div className='mb-2'>
                  <div
                    onClick={() =>
                      setOpenToggle(param => ({
                        ...param,
                        delivery: !param.delivery
                      }))
                    }
                    className={`mb-2 toggle-container ${
                      !openToggle.delivery ? 'border-shadow' : ''
                    }`}
                  >
                    <label className='delivery-options__label mb-0'>
                      Choose a Shipping Option
                    </label>
                    <img
                      className={`arrow-icon ${
                        openToggle.delivery ? 'upside-down' : ''
                      }`}
                      src={SelectArrowBlue}
                      alt=''
                    />
                  </div>
                  {openToggle.delivery && (
                    <DeliveryOptions2
                      loading={loading}
                      shopNShipRates={
                        isChinaImport ? shopNShipRates : activeRate
                      }
                      selectedRate={selectedRate}
                      handleRateSelection={
                        isChinaImport ? handleRateSelection : null
                      }
                    />
                  )}
                </div>
                {isImportBooking ? (
                  <div className='mb-2'>
                    <div
                      className={`mb-2 toggle-container ${
                        !openToggle.lastMile ? 'border-shadow' : ''
                      }`}
                      onClick={() =>
                        setOpenToggle(param => ({
                          ...param,
                          lastMile: !param.lastMile
                        }))
                      }
                    >
                      <label className='delivery-options__label mb-0'>
                        Choose a Delivery Partner
                      </label>
                      <img
                        className={`arrow-icon ${
                          openToggle.lastMile ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.lastMile && (
                      <>
                        <p
                          style={{
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#545859',
                            fontWeight: 500
                          }}
                        >
                          Your delivery location is out of{' '}
                          <b>Lagos, Nigeria.</b> Please select a delivery method
                          to get it delivered to you
                        </p>

                        <DeliveryOptions1
                          showLabel={false}
                          loading={lastMileLoading}
                          rates={lastMileRates}
                          selectedRate={selectedLastMile}
                          handleRateSelection={handleLastMileSelection}
                          isLastMile={isImportBooking}
                          shipmentDetail={shopNShipRates}
                        />
                      </>
                    )}
                  </div>
                ) : null}
                <div className='mb-2'>
                  <div
                    className={`mb-2 toggle-container ${
                      !openToggle.insurance ? 'border-shadow' : ''
                    }`}
                    onClick={() =>
                      setOpenToggle(param => ({
                        ...param,
                        insurance: !param.insurance
                      }))
                    }
                  >
                    <label className='delivery-options__label'>
                      Choose an Insurance Option
                    </label>
                    <img
                      className={`arrow-icon ${
                        openToggle.insurance ? 'upside-down' : ''
                      }`}
                      src={SelectArrowBlue}
                      alt=''
                    />
                  </div>
                  {openToggle.insurance && (
                    <InsuranceOptions
                      insurancePlans={insurancePlans}
                      totalItemValue={totalItemValue}
                      values={values}
                      handleInsuranceSelection={handleInsuranceSelection}
                      shopNshipDetails={shopNShipDetails}
                    />
                  )}
                </div>
              </div>
              {isChinaImport ? null : (
                <Grid container rowSpacing='16px' columnSpacing='10px'>
                  <div id='input-control'>
                    <label htmlFor='receiverPaymentDetails'>
                      <span>Was payment made using receiver details? </span>
                    </label>
                    <div className='radio-group'>
                      <div className='radio-group-option'>
                        <input
                          type='radio'
                          id='receiverPaymentDetailsTrue'
                          name='receiverPaymentDetails'
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('receiverPaymentDetails', true)
                          }
                          disabled={!!shopNShipDetails?.id}
                          checked={values?.receiverPaymentDetails === true}
                        />
                        <span
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('receiverPaymentDetails', true)
                          }
                        >
                          Yes I did
                        </span>
                      </div>
                      <div className='radio-group-option'>
                        <input
                          type='radio'
                          id='receiverPaymentDetailsFalse'
                          name='receiverPaymentDetails'
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('receiverPaymentDetails', false)
                          }
                          disabled={!!shopNShipDetails?.id}
                          checked={values?.receiverPaymentDetails === false}
                        />
                        <span
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('receiverPaymentDetails', false)
                          }
                        >
                          No, it was purchased by someone else
                        </span>
                      </div>
                    </div>
                    <small>
                      {touched.receiverPaymentDetails &&
                        errors.receiverPaymentDetails}
                    </small>
                  </div>
                  <div id='input-control'>
                    <label htmlFor='uploadingMyDocuments'>
                      <span>Document Upload </span>
                    </label>
                    <div className='radio-group'>
                      <div className='radio-group-option'>
                        <input
                          type='radio'
                          id='uploadingMyDocumentsTrue'
                          name='uploadingMyDocuments'
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('uploadingMyDocuments', true)
                          }
                          disabled={!!shopNShipDetails?.id}
                          checked={JSON.parse(values?.uploadingMyDocuments)}
                        />
                        <span
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('uploadingMyDocuments', true)
                          }
                        >
                          I am Uploading my documents
                        </span>
                      </div>
                      <div className='radio-group-option'>
                        <input
                          type='radio'
                          id='uploadingMyDocumentsFalse'
                          name='uploadingMyDocuments'
                          disabled={!!shopNShipDetails?.id}
                          checked={!JSON.parse(values?.uploadingMyDocuments)}
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('uploadingMyDocuments', false)
                          }
                        />
                        <span
                          onClick={() =>
                            !shopNShipDetails?.id &&
                            handleStateChange('uploadingMyDocuments', false)
                          }
                        >
                          I am uploading someone else’s documents
                        </span>
                      </div>
                    </div>
                    <small>
                      {touched.uploadingMyDocuments &&
                        errors.uploadingMyDocuments}
                    </small>
                  </div>
                </Grid>
              )}
              <div className='mt-5'>
                <div
                  className={`verification__content
                     
                    `}
                >
                  <div
                    style={{
                      textAlign: 'left'
                    }}
                    className='verification__header text-left'
                  >
                    <p
                      style={{
                        fontSize: '14px',
                        lineHeight: '24px'
                      }}
                    >
                      I certify that I’m using this for genuine purposes; else,
                      Topship may involve law enforcement agencies if faulted.
                      And I do understand that knowingly making any false or
                      fraudulent representations to any online businesses may
                      violate federal or state criminal statutes, and may result
                      in a fine, imprisonment, or both.
                    </p>
                  </div>

                  <div
                    className='mt-2'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '6px',
                        backgroundColor: isChecked ? '#6B50F6' : 'transparent',
                        border: '2px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '8px'
                      }}
                    >
                      {isChecked && (
                        <img width={20} src={WhiteCheck} alt='white check' />
                      )}
                    </div>
                    <label
                      className='mb-0'
                      style={{
                        color: 'black',
                        fontSize: '14px',
                        marginLeft: '10px'
                      }}
                    >
                      I Agree to the above terms
                    </label>
                  </div>
                </div>
              </div>
              <div className='new-shop-n-ship__modal__body__form__item__buttons'>
                <div
                  className={`new-shop-n-ship__modal__body__form__item__button${
                    shopNShipDetails?.id ? ' disabled' : ''
                  }`}
                  onClick={() =>
                    !shopNShipDetails?.id &&
                    history.push(filteredLinks[currentIndex - 1].link)
                  }
                  disabled={!!shopNShipDetails?.id}
                >
                  Go Back
                </div>

                <SubmitButton disabled={!isChecked} text={`Next`} />
              </div>
            </form>
          </div>
        </div>
      </div>
      <BusyOverlay loading={false} speed='1.7s' />
    </NewShopNShipStepsLayout>
  );
};

export default ShopNShipOtherInformation;
