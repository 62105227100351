import { IconButton, Skeleton } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-shipment.svg';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Wrapper from '../../../components/Layouts/wrapper';
import Input from '../../../components/new-dashboard/custom-input';
import {
  filteredShopNShipLinksState,
  shipmentDataLoadingState,
  shopNShipDetailsState,
  shopnshipListState
} from '../../../recoil/atoms';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import RecentShipment from '../../../components/Commons/recent-shipment';
import Pagination from '../../../components/Commons/pagination';
import usePagination from '../../../utilities/usePagination';
import EmptyList from '../../../components/empty-list';
import { useGetShopnshipsQuery } from '../../../operations/queries';
import PastShipmentLoader from '../PastShipments/past-shipment-loader';
import BusyOverlay from '../../../components/busy-overlay';
import PastShipmentDetailsModal from '../PastShipments/past-shipment-details-modal';
import debounce from 'lodash.debounce';
import insurancePlans from '../../../constants/insurance-plans';
import testJSON from '../../../utilities/test-json';
import emptyStateIcon from '../../../assets/svg/empty.svg';
import BookShipmentModal from '../../../components/modals/book-shipment-modal';
import TrackingIdModal from '../../../components/modals/tracking-id-update-modal';
import { useHistory } from 'react-router-dom';

// const states = {
//   US: 'New York',
//   CN: 'China',
//   UK: 'London',
//   CA: 'Ontario'
// };

const PastShopnShips = () => {
  const take = 10;
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [shipmentItem, setShipmentItem] = useState();
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);
  const [shopnshipList] = useRecoilState(shopnshipListState);
  const [shipmentDataLoading] = useRecoilState(shipmentDataLoadingState);
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);

  const filterSkeletonStyles = {
    borderRadius: '50%',
    backgroundColor: 'rgba(34, 66, 143, 0.1)'
  };

  const chipEnums = {
    ALL: 'All',
    PAID: 'Paid',
    IMPORT: 'Import',
    EXPORT: 'Export',
    DOMESTIC: 'Domestic',
    FAILED_DELIVERIES: 'FailedDeliveries',
    CLARIFICATION_NEEDED: 'ClarificationNeeded'
  };

  const filters = {
    all: 'all',
    confirmed: 'confirmed',
    intransit: 'deliveryinprogress',
    delivered: 'delivered'
  };

  const [filterShipments, setFilterShipment] = useState(filters.all);
  const [filter, setFilter] = useState({
    status: chipEnums.ALL,
    data: ''
  });

  const {
    start,
    end,
    totalCount,
    disableNext,
    disablePrevious,
    currentPage,
    onPreviousPage,
    onNextPage,
    setStart,
    setEnd,
    setCurrentPage
  } = usePagination({
    take,
    currentPage: shopnshipList?.currentPage,
    totalCount: shopnshipList?.totalCount
  });
  const dateStyles = { marginTop: 'calc(2% - 3px)' };
  const [isChinaImport, setIsChinaImport] = useState(false);
  const paginationSettings = {
    start,
    end,
    take,
    disableNext,
    disablePrevious,
    totalCount,
    loading: shipmentDataLoading,
    onNextPage,
    onPreviousPage
  };

  const getShopnshipsQuery = useGetShopnshipsQuery(isChinaImport);

  const handleSetFilter = value => {
    setCurrentPage(1);
    setStart(1);
    setEnd(take);
    setFilterShipment(value);
  };

  const handleSearchQuery = debounce(
    e =>
      onShipmentsQuery({
        page: 0,
        pageSize: take,
        search: e.target.value || ''
      }),
    500
  );

  const handleSearchTrackingNumber = debounce(e => {
    onShipmentsQuery({
      page: 0,
      pageSize: take,
      search: ''
    });
  }, 500);

  const openTrackingNoUpdate = sNSShipmentId => {
    setShipmentId(sNSShipmentId);
    setShowTrackingModal(true);
  };

  const onShipmentsQuery = query => {
    return new Promise((resolve, reject) => {
      getShopnshipsQuery(query.page + 1, query.pageSize, query.search, {
        isChinaImport: false,
        ...filter.data
      })
        .then(response => {
          resolve({
            data: response.getShopnships?.list || [],
            page: response.getShopnships?.currentPage - 1,
            totalCount: response.getShopnships?.totalCount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  const onFilterChange = data => {
    setFilter(data);
  };

  useEffect(() => {
    if (filterShipments !== filters.all) {
      getShopnshipsQuery(currentPage, take, filterShipments, {
        isChinaImport: false,
        ...filter?.data
      });
    } else if (filter?.data) {
      getShopnshipsQuery(currentPage, take, '', {
        isChinaImport: false,
        ...filter?.data
      });
    } else {
      getShopnshipsQuery(currentPage, take, '', { isChinaImport: false });
    }
    //eslint-disable-next-line
  }, [currentPage, take, filterShipments, filter]);

  return (
    <>
      {showTrackingModal && (
        <TrackingIdModal
          setShowTrackingModal={setShowTrackingModal}
          shipmentId={shipmentId}
        />
      )}

      {openModal && (
        <PastShipmentDetailsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shipmentItem={shipmentItem}
          setShipmentItem={setShipmentItem}
          currentPage={currentPage}
          isShopnship
          isChinaImport={isChinaImport}
        />
      )}
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
      />
      <Wrapper>
        <BusyOverlay loading={shipmentDataLoading} />
        <div className='recent-shipments-container'>
          <div className='recent-shipments-filters-container'>
            <div className='new-wallet__transactions__filters recent-shipment-filters'>
              {Object.values(filters).map(
                filterType =>
                  filterType && (
                    <div
                      key={filterType}
                      onClick={() => {
                        if (filterType === 'all') {
                          onFilterChange(filter?.data);
                        }
                        handleSetFilter(filterType);
                      }}
                      className={`new-wallet__transactions__filter${
                        filterShipments === filterType ? ' active' : ''
                      }`}
                    >
                      <div className='new-wallet__transactions__filter__wrap'>
                        <div className='new-wallet__transactions__filter__content'>
                          <div
                            style={{ textTransform: 'capitalize' }}
                            className='new-wallet__transactions__filter__content__name'
                          >
                            <span>
                              {filterType === 'deliveryinprogress'
                                ? 'in progress'
                                : filterType}
                            </span>
                          </div>
                          <div className='new-wallet__transactions__filter__content__value'>
                            {filterShipments !== filterType ? (
                              <Skeleton sx={filterSkeletonStyles} />
                            ) : !shipmentDataLoading ? (
                              <span>
                                {shopnshipList?.list &&
                                  shopnshipList?.totalCount}
                              </span>
                            ) : (
                              <span>
                                {shopnshipList?.list &&
                                  shopnshipList?.totalCount}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className='recent-shipments-ship-now'>
              <button onClick={() => history.push(filteredLinks[0].link)}>
                Ship Now
              </button>
            </div>
          </div>
          <div className='recent-shipments-controls'>
            <div id='search' className='recent-shipments-control-items'>
              <Input
                onChange={handleSearchQuery}
                type='text'
                containerClassName='new-addresses__modal__item__input'
                customLabel={'Tracking ID'}
                placeholder='Search by Tracking ID'
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                fullWidth
              />
            </div>

            <div
              id='shipment-type'
              className='recent-shipments-control-items recent-shipments-control-items-shipment-type'
            >
              <Input
                onChange={e => {
                  setFilter(filter => ({
                    ...filter,
                    data: { trackingNumber: e.target.value }
                  }));
                  handleSearchTrackingNumber(e);
                }}
                type='text'
                containerClassName='new-addresses__modal__item__input'
                customLabel={'Tracking Number'}
                placeholder='Search by Tracking Number'
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                fullWidth
              />
            </div>
            <div className='recent-shipments-control-items' style={dateStyles}>
              <div className='recent-shipments-date-filter-controls form-container'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label className='form-item-label'>From</label>
                  <KeyboardDatePicker
                    margin='normal'
                    fullWidth
                    id='startDate'
                    format='dd/MM/yyyy'
                    value={startDate}
                    onChange={date => {
                      if (date) {
                        setStartDate(date);
                        onFilterChange({
                          status: filter?.status,
                          data: {
                            ...(filter?.data || {}),
                            dateRange: { from: date, to: endDate }
                          }
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className='recent-shipments-control-items' style={dateStyles}>
              <div className='recent-shipments-date-filter-controls form-container'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label className='form-item-label'>To</label>
                  <KeyboardDatePicker
                    fullWidth
                    margin='normal'
                    id='endDate'
                    format='dd/MM/yyyy'
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 }
                    }}
                    value={endDate}
                    onChange={date => {
                      if (date) {
                        setEndDate(date);
                        onFilterChange({
                          status: filter?.status,
                          data: {
                            ...(filter?.data || {}),
                            dateRange: { from: startDate, to: date }
                          }
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>

          <div className='recent-shipment-list'>
            {shipmentDataLoading
              ? [...Array(take).keys()].map(key => (
                  <PastShipmentLoader key={key} loading={shipmentDataLoading} />
                ))
              : shopnshipList?.list?.map(shopnship => {
                  const shippingFrom =
                    shopnship?.shippingFrom === 'CHINA'
                      ? 'CN'
                      : shopnship?.shippingFrom.substring(0, 2)?.toUpperCase();
                  const receiverAddress = ` Room 103 - ELO75, Building F, Dehui Wanggang Science
                          and Technology Park, No. 38, Wanggang Industry 3rd
                          Road`;

                  const shipment = {
                    id: shopnship?.id,
                    estimatedDeliveryDate: shopnship?.updatedDate,
                    updatedDate: shopnship?.updatedDate,
                    pricingTier: shopnship?.pricingTier || 'Express',

                    insuranceCharge:
                      shopnship?.insuranceCharge ||
                      insurancePlans[0].amount * 100,
                    insuranceType:
                      shopnship?.insuranceType || insurancePlans[0].value,
                    senderDetail: {
                      name: shopnship?.user?.fullName,
                      email: shopnship?.user?.email,
                      addressLine1:
                        shopnship?.shippingFrom === 'CHINA'
                          ? receiverAddress
                          : shopnship?.shippingFrom,
                      state:
                        shippingFrom === 'US'
                          ? 'New York'
                          : shippingFrom === 'CA'
                          ? 'Canada'
                          : 'London',
                      countryCode: shippingFrom === 'UK' ? 'GB' : shippingFrom
                    },
                    receiverDetail: {
                      name: shopnship?.deliveryName,
                      email: shopnship?.email,
                      addressLine1: shopnship?.deliveryAddress,
                      city: shopnship?.deliveryCity || '',
                      state: shopnship?.deliveryState || 'Lagos',
                      countryCode: 'NG'
                    },
                    trackingId: shopnship?.trackingId,
                    shipmentStatus: shopnship?.status,
                    totalCharge: shopnship?.totalCharge,
                    initialCharge: shopnship?.initialCharge,
                    finalCharge: shopnship?.finalCharge,
                    items: testJSON(shopnship.itemDescription)
                      ? JSON.parse(shopnship?.itemDescription)
                      : [
                          {
                            estimatedWeight: shopnship?.estimatedWeight || '',
                            proofOfId: shopnship?.proofOfId || '',
                            evidenceOfPurchase:
                              shopnship?.evidenceOfPurchase || ''
                          }
                        ]
                  };

                  return (
                    <div key={shipment?.id}>
                      <div
                        className='shipment-item'
                        onClick={() => {
                          setOpenModal(true);
                          setShipmentItem(shipment);
                          setIsChinaImport(shopnship?.shippingFrom === 'CHINA');
                        }}
                      >
                        <RecentShipment
                          key={shipment?.id}
                          isShopnship
                          shipment={shipment}
                          shopnship={shopnship}
                          isChinaImport={shopnship?.shippingFrom === 'CHINA'}
                          openTrackingNoUpdate={openTrackingNoUpdate}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>

          {!shipmentDataLoading && shopnshipList?.list?.length >= 1 ? (
            <div className='past-shipment-list-pagination'>
              <Pagination {...paginationSettings} />
            </div>
          ) : null}
        </div>
        {!shipmentDataLoading && shopnshipList?.list?.length === 0 ? (
          <EmptyList
            emptyIcon={emptyStateIcon}
            heading='You don’t have any Shipments yet'
            subHeading='Request a new shipment and the log of all your shipments would appear here.'
          />
        ) : null}
      </Wrapper>
    </>
  );
};

export default PastShopnShips;
