import termsImage from '../../assets/image/terms-image.jpg';
import Header from '../../components/Commons/header';

const Terms = () => {
  return (
    <>
      <Header />
      <div className='terms-and-conditions'>
        <img
          className='terms-and-conditions__logo'
          src={termsImage}
          alt='topship'
        ></img>

        <h2 className='terms-and-conditions__caption'>Terms and Conditions</h2>

        <h3 className='terms-and-conditions__heading mt-2'>Important Notice</h3>
        <p>
          When ordering Topship’s service as
          "Shipper/Merchant/Customer/Partner/Sender/User/Client”, you are
          agreeing, on your behalf and on behalf of the receiver of the Shipment
          ("Receiver") and anyone else with an interest in the Shipment, that
          the Terms and Conditions shall apply.
        </p>
        <p>
          "Shipment" means all documents, packages, or parcels that travel under
          one waybill and which may be carried by any means Topship and all its
          courier partners chooses, including air, land, or any other carrier.
        </p>
        <p>
          A "waybill" shall include any Shipment identifier or document produced
          by Topship and its partners or Shipper automated systems such as a
          label, barcode, waybill, or consignment note as well as any electronic
          version thereof. Every Shipment is transported on a limited liability
          basis as provided herein.
        </p>
        <p>
          If Shipper requires greater protection, then insurance may be arranged
          at an additional cost. (Please see below for further information).
        </p>
        <p>"Topship" means any member of the Topship Worldwide Network.</p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          1. <span>Personal Information Data & Image Collection</span>
        </h3>
        <p>
          Please note that all personal information shared with us (such as full
          name, email address, telephone number, address and so on) via our
          website or apps or any of our customer channels is processed in order
          to ensure identification of individual customers. These customer
          segments ranges from our current customers, past customers,
          potentially new customers, blog post subscribers, social media
          followers and prospective customers, and or all other persons that in
          one way or the other interact with our Platform (“Users”) and other
          individuals whom we communicate or deal with, jointly and/or
          severally.
        </p>
        <p>
          Hereby it is noted that using or interacting with any of Topship’s
          services serves as a voluntary confirmation for your personal data &
          Image alongside all other information provided to be used and
          processed in accordance to services provided.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          2. <span>Why do we Collect Personal Information</span>
        </h3>
        <ul>
          <li>In order to provide you with the required Services</li>
          <li>In order to respond to your questions or requests</li>
          <li>
            In order to improve features, website content, and analyze data &
            images to develop products and services
          </li>
          <li>In order to address inappropriate use of our Services</li>
          <li>
            In order to prevent, detect and manage risk against misinformation,
            wrong use, and illegal activities using internal and third-party
            screening tools
          </li>
          <li>
            In order to send you marketing content, blog and service updates
            sent by Topship. An option to unsubscribe is available for users who
            no longer wish to interact with the platform
          </li>
          <li>
            In order to verify your identity and the information you provide in
            line with our statutory obligations using internal and third-party
            tools
          </li>
          <li>In order to maintain up-to-date records</li>
          <li>
            In order to resolve disputes that may arise, including
            investigations by law enforcement or regulatory bodies, any other
            purpose that we disclose to you while providing our Services to you
          </li>
        </ul>
        <p>
          With your consent, additional Personal Information about you from
          third parties and other identification/verification services may be
          retrieved from you. Personal information may also be collected through
          other methods such as ; emails, surveys, and other forms of
          communication. Once you begin using our Services, we will keep records
          of and collect information regarding your activities as it relates to
          our Services.
        </p>
        <p>
          Please note that we will not share or disclose your Personal
          Information with a third party without your consent except as may be
          required for the purpose of providing you with our Services or under
          applicable legislations.
        </p>
        <p>
          We collect Personal Information only for identified purposes and for
          which consent has been obtained. Such Personal Information cannot be
          reused for another purpose that is incompatible with the original
          purpose, except consent is obtained for such purpose.
        </p>
        <p>
          We limit Personal Information collection and usage of data & images
          that is relevant, adequate, and absolutely necessary for carrying out
          the purpose for which the data & Images are processed. We will
          evaluate whether and to what extent the processing of Personal
          Information is necessary and where the purpose allows, anonymised data
          and images will be used.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          3. <span>Cookies</span>
        </h3>
        <p>
          Cookies are used to identify you as a User and make your user
          experience easier, customize our Services, content, and advertising,
          and where applicable help you ensure that your account security is not
          compromised. We also use cookies to mitigate risk and prevent fraud,
          promote trust and safety on our website. Cookies allow our servers to
          remember IP addresses, date and time of visits, monitor web traffic
          and prevent illegal activities.
        </p>
        <p>
          Our cookies do not store personal or sensitive information. They
          simply hold a unique random reference to you so that once you visit
          our website, we can recognize who you are and provide certain content
          to you. If your browser or browser add-on permits, you have the choice
          to disable cookies on our website, however this may impact your
          experience with using our website.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          4. <span>Protecting your privacy is important</span>
        </h3>
        <p>
          We shall establish adequate controls in order to protect the integrity
          and confidentiality of your Personal Information, both in digital and
          physical format and to prevent your Personal Information from being
          accidentally or deliberately compromised.
        </p>
        <p>
          We are committed to managing your Personal Information in line with
          global industry best practices. We protect your Personal Information
          using physical, technical, and administrative security measures to
          reduce the risks of loss, misuse, unauthorized access, disclosure and
          alteration, we also use industry recommended security protocols to
          safeguard your Personal Information. Other security safeguards include
          but are not limited to data encryption, firewalls, and physical access
          controls to our building and files and only granting access to your
          Personal Information to only employees who require it to fulfill their
          job responsibilities. No Personal Information processing will be
          undertaken by an employee who has not been authorized to carry such
          out as part of their legitimate duties.
        </p>
        <p>
          We do not sell, trade or rent Personal Information to anyone. However,
          to enable us to render our Services to you on our website, we may
          share your information with trusted third parties, such third parties
          include sanctions screening and identity verification services as well
          as any third parties that you have directly authorized to receive your
          Personal Information.
        </p>
        <p>
          We may disclose your Personal Information in compliance with
          applicable law or a legal obligation to which we are bound. Please
          note that third-party sites you engage with through our Services will
          have their privacy policies, and we are therefore not responsible for
          their actions, including their information protection practices. The
          use of your information by such third parties will be subject to their
          applicable privacy policy, which you should carefully review.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          5. <span>Third Party Processor within Nigeria</span>
        </h3>
        <p>
          We may engage the services of third parties to process your Personal
          Information. The processing by such third parties shall be governed by
          a written contract with us to ensure adequate protection and security
          measures are put in place by the third party for the protection of
          Personal Information in accordance with the terms of this Privacy
          Policy.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          6. <span>Transfer of Personal Information to Foreign Country</span>
        </h3>
        <p>
          In providing you with the Services, we may rely on third-party servers
          located in foreign jurisdictions from time to time, which as a result,
          may require the transfer or maintenance of your Personal Information
          on computers or servers in foreign jurisdictions.
        </p>
        <p>
          Where such Personal Information is to be transferred to a country
          outside Nigeria, we shall put adequate measures in place to ensure the
          security of such Personal Information. We shall, among other things,
          confirm whether the country is on the National Information Technology
          Development Agency (“NITDA”) Whitelist of Countries with adequate data
          protection laws.
        </p>
        <p>
          The transfer and subsequent processing of such Personal Information
          would be in accordance with the provisions of the Nigeria Data
          Protection Regulation ("NDPR”) which we comply with. We will therefore
          only transfer and permit the processing of Personal Information
          outside of Nigeria where:
        </p>
        <ul>
          <li>Consent has been obtained</li>
          <li>
            Transfer and processing are necessary for the performance of a
            contract between us or implementation of pre-contractual measures
            taken at your request; the transfer and processing are necessary to
            conclude a contract between us and a third party in your interest;
            the transfer and processing are necessary for reason of public
            interest
          </li>
          <li>
            Transfer and processing are for the establishment, exercise or
            defense of legal claims
          </li>
          <li>
            Transfer and processing are necessary to protect your vital
            interests or that of other persons, where you are physically or
            legally incapable of giving consent.
          </li>
        </ul>
        <p>
          Necessary steps will be taken to ensure that your Personal Information
          is transmitted and processed in a safe and secure manner. Details of
          the protection given when your Personal Information is transferred
          outside Nigeria shall be provided to you upon request.
        </p>
        <p>
          We will retain your Personal Information for as long as is needed to
          provide our Services to you, comply with our legal and statutory
          obligations or verify your information with the required verification
          authorities. Therefore, even after discontinuance of our Services, we
          will retain certain Personal Information and transaction data to
          comply with our legal and regulatory obligations. All Personal
          Information shall be destroyed by us where we have satisfied the legal
          retention timeframe.
        </p>
        <p>
          For all Personal Information and records obtained, used and stored by
          us, we shall perform periodical reviews of the data retained to
          confirm the accuracy, purpose, validity and requirement to retain. The
          length of storage of your Personal Information shall, amongst other
          things, be determined by:
        </p>
        <ul>
          <li>
            the contract terms agreed between us or as long as it is needed for
            the purpose for which it was obtained; or
          </li>
          <li>
            whether the transaction or relationship has statutory implication or
            a required retention period; or
          </li>
          <li>
            whether there is an express request for deletion of the Personal
            Information by you, provided that such request will only be treated
            where you are not under any investigation which may require us to
            retain such Personal Information or there is no subsisting
            contractual arrangement with you that would require the processing
            of the Personal Information; or
          </li>
          <li>
            whether we have another lawful basis for retaining that information
            beyond the period for which it is necessary to serve the original
            purpose.
          </li>
        </ul>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          7. <span>Eligibility</span>
        </h3>
        <p>
          You must be at least 18 years old and able to enter into legally
          binding contracts to access and use Topship or register a Topship
          account. By accessing or using the application, you represent and
          warrant that you are 18 or older and have the legal capacity and
          authority to enter into a contract. If you are under age 18, you may
          not, under any circumstances or for any reason, use the Services. We
          may, in our sole discretion, refuse to offer the Services to any
          person or entity and change its eligibility criteria at any time. You
          are solely responsible for ensuring that these Terms are in compliance
          with all laws, rules and regulations applicable to you and the right
          to access the Services is revoked where these Terms or use of the
          Services is prohibited or to the extent offering, sale or provision of
          the Services conflicts with any applicable law, rule or regulation.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          8. <span>Acceptance of Terms</span>
        </h3>
        <p>
          When you access our website and/or our mobile applications ("our
          website" (which includes our blog), "our app" as a merchant and
          customer, you're agreeing to be bound by the following terms and
          conditions; The services offered are subject to acceptance of all the
          terms and conditions contained in these terms and all other operating
          rules, policies, and procedures that may be published on the website
          by the Company, which are incorporated by reference, including
          operating rules, policies, and procedures of third party service
          providers to the website that are referenced herein.
        </p>
        <p>
          Certain parts of the Services may be subject to additional terms and
          conditions specified by us from time to time; your use of such
          Services is subject to those additional terms and conditions, which
          are incorporated into these Terms. These Terms apply to all users of
          the Services, including, without limitation, users who are
          contributors of content, information, and other materials or services,
          registered or otherwise.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          9.
          <span>Registration</span>
        </h3>
        <p>
          In order to initiate and commence the use of our services, all users
          must create an account. If you are creating a Topship account for a
          company or other legal entity, you represent and warrant that you have
          the authority to legally bind that entity and grant us all permissions
          and licenses provided in these terms.
          <br />
          <br />
        </p>
        <p>
          You can create a Topship account using an active email address and
          creating a password. By creating an account, you agree to provide and
          maintain true, accurate, current and complete information about
          yourself as prompted by the registration form. You are also
          responsible for safeguarding the password that you use to access your
          account and for any activities or actions under your password. You
          agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <p>
          You may not use as a username the name or trademark of another person
          or entity or that is not lawfully available for use, or that is
          subject to any rights of another person or entity other than you
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene. You are liable for any and all
          activities conducted through your Topship account, unless such
          activities are not authorized by you and you are not otherwise
          negligent (such as failing to report the unauthorized use or loss of
          your credentials). When you create an account with us, you must
          provide us with information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms which
          may result in immediate termination of your account on our service.
          You agree to strictly observe the security and authentication
          procedures of the website/service and you will log out from the
          website by taking proper steps at the end of every visit.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          10.
          <span>Unacceptable Shipments</span>
        </h3>
        <p>A Shipment is deemed unacceptable if:</p>
        <ul>
          <li>
            No customs declaration is made when required by applicable customs
            regulations.
          </li>
          <li>
            Package contains counterfeit goods, animals, perishable cooked food
            items, bullion, currency, gemstones; weapons, explosives, and
            ammunition; human remains; illegal items, such as ivory and
            narcotics.
          </li>
          <li>
            It is classified as hazardous material, dangerous goods, prohibited
            or restricted articles by IATA (International Air Transport
            Association), ICAO (International Civil Aviation Organization), ADR
            (European Road Transport Regulation on dangerous goods) or other
            relevant organization ("Dangerous Goods").
          </li>
          <li>
            Addressed incorrectly or not properly marked or its packaging is
            defective or inadequate to ensure safe transportation with ordinary
            care in handling, OR any such goods that Topship deems as prohibited
            or restricted.
          </li>
        </ul>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          11.
          <span>Deliverables and Undeliverables</span>
        </h3>
        <p>Shipments cannot be delivered to PO Boxes or Army bases.</p>
        <p>
          Shipments are delivered to the Receiver's address given by the
          Shipper/Sender but not necessarily to the named Receiver personally.
          Shipments to addresses with a central receiving area will be delivered
          to that area.
        </p>
        <p>
          Topship may notify the receiver of an upcoming delivery or a missed
          delivery. The receiver may be offered alternative delivery options
          such as delivery on another day, no signature required,redirection, or
          collection at a Service Point, the Shipper may exclude certain
          delivery options on request.
        </p>
        <p>
          If the Shipment is deemed to be unacceptable as described, or it has
          been undervalued for customs purposes, or Receiver cannot be
          reasonably identified or located, or Receiver refuses delivery or to
          pay Customs Duties or other Shipment charges; Topship shall use
          reasonable efforts to return the Shipment to Shipper at Shipper's
          cost, failing which the Shipment may be released, disposed of or sold
          without incurring any liability whatsoever to Shipper or anyone else,
          with the proceeds applied against Customs Duties, Shipment charges and
          related administrative costs with the balance of the proceeds of a
          sale to be returned to Shipper.
        </p>
        <p>
          Topship shall have the right to destroy any Shipment which any law
          prevents Topship from returning to Shipper as well as any Shipment of
          Dangerous Goods.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          12.
          <span>Inspection</span>
        </h3>
        <p>
          Topship and its partners have the right to open and inspect a Shipment
          without notice for safety,security, customs or other regulatory
          reasons.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          13.
          <span>Shipment Charges & Billing</span>
        </h3>
        <p>
          Topship’s Shipment charges are calculated according to final
          destination, weight and/or the higher of actual or volumetric weight
          per piece and any piece may be re-weighed andre-measured by Topship to
          confirm this calculation.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          14.
          <span>Topship’s Liability </span>
        </h3>
        <p>
          Topship's liability in respect of any one Shipment shall be limited to
          N10,000 irrespective of the declared or actual value of the
          shipment(s). If Shipper regards these limits as insufficient it must
          make a special declaration of value and request insurance as described
          under the insurance clause or make its own insurance arrangements.
        </p>
        <p>
          Topship’s liability is also strictly limited to direct loss and damage
          to a Shipment only, all other types of loss or damage are excluded
          (including but not limited to lost profits, income, interest,future
          business), whether such loss or damage is special or indirect, and
          even if the risk of such loss or damage was brought to topship’s
          attention.
        </p>
        <p>
          Topship will make every reasonable effort to deliver the Shipment
          according to its partner’s regular delivery schedules, but these
          schedules are not binding and do not form part of the contract.
          Topship is not liable for any damages or loss caused by delay.
        </p>
        <p>
          Topship its subsidiaries, affiliates, and its licensors do not warrant
          that a) the service will function uninterrupted, secure or available
          at any particular time or location; b) any errors or defects will be
          corrected; c) the service is free of viruses or other harmful
          components; or d) the results of using the service will meet your
          requirements.
        </p>
        <p>
          Topship shall not be liable or obligated with respect to any subject
          matter of this agreement or under contract, negligence, strict
          liability or other legal or equitable theory for: any special,
          incidental, consequential, or exemplary damages (including, without
          limitation, loss of revenue, goodwill, or anticipated profits), data
          loss or cost of procurement of substitute goods or services, and/or
          any matter beyond such parties’ reasonable control.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          15.
          <span>Shipper's Warranties and Indemnities</span>
        </h3>
        <p>
          Shipper shall indemnify and hold topship harmless for any loss or
          damage arising out of Shipper's failure to comply with the following
          warranties and representations:
        </p>
        <ul>
          <li>
            All information provided by Shipper or its representatives is
            complete and accurate;
          </li>
          <li>The Shipment is acceptable for transport.</li>
          <li>
            The Shipment was prepared in secure premises by reliable persons and
            was protected against unauthorized interference during preparation,
            storage and any transportation to Topship.
          </li>
          <li>
            Shipper has complied with all applicable customs, import, export,
            data protection laws, sanctions, embargoes and other laws and
            regulations
          </li>
          <li>
            Shipper has obtained all necessary consents in relation to personal
            data provided to topship including Receiver's data as may be
            required for transport, customs clearance and delivery,such as
            e-mail address and mobile phone number
          </li>
          <li>
            Shipper has carefully considered the risks involved with using the
            website, and its services; if you are registering or accepting these
            Terms on behalf of a legal entity such as a company, trust or
            partnership, you are legally authorized to do so and we may request
            evidence of such legal entitlement (by way of a copy of any document
            which shows the valid and subsisting authorisation).
          </li>
          <li>
            Shipper is not breaching any laws or regulations that are applicable
            to you or any company, trust or partnership upon whose instructions
            you are acting.
          </li>
          <li>
            Shipper fully understands the risks involved in the transaction and
            undertakes to bear all risks related to the Transaction.
          </li>
        </ul>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          16.
          <span>Prohibited Activities</span>
        </h3>
        <p>The Shipper agrees that:</p>
        <ul>
          <li>
            The services shall not be used for any purpose that is prohibited by
            these terms. You are responsible for all of your activity in
            connection with your account, violation of the rules may result in
            the termination and cancellation of your account.
          </li>
          <li>
            You shall not utilize the website or the Services in connection with
            any transaction that is illegal or involves any illegal items, or is
            for any illegal purpose. Topship In its sole discretion, may refuse
            to complete any transaction that we have reason to believe is
            unauthorized or may violate any law, rule or regulation.
          </li>
          <li>
            Kindly find an updated list on{' '}
            <a href='https://docs.google.com/spreadsheets/d/1S6_NATMXzF7XbUupu7vSwWJ9fQCNQ_AY/edit?usp=sharing&ouid=104224926610291916973&rtpof=true&sd=true'>
              Prohibited and Restricted Items
            </a>{' '}
            per country.
          </li>
        </ul>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          17.
          <span>Paid Claims, Customer Refunds & Withdrawals</span>
        </h3>
        <p>
          Every wallet withdrawal request must be made officially to
          hello@topship.africa via the registered email on your Topship account.
          Protecting your withdrawal requests is a priority to us, and we verify
          your identity to avoid data identity theft.
        </p>
        <p>
          Please note all wallet withdrawal requests, customer refunds and
          successful claims are processed at the end of each working week and
          confirmation received latest by the following working Monday.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          18.
          <span>Insurance</span>
        </h3>
        <p>
          Topship may be able to arrange insurance covering up to a certain
          percentage of the declared value in respect of loss of or damage to
          the Shipment while in Topship’s care; provided that the Shipper so
          instructs topship by completing the insurance section on the form and
          by paying the applicable premium. There are various insurance options
          for customers to choose from :
        </p>
        <ul>
          <li>
            Basic Insurance : All packages shipped via the Topship platform have
            a basic insurance coverage up to N10,000 (Ten thousand naira) only
            at no charge.
          </li>
          <li>
            Premium Insurance : A fee of N12,500 is applicable for damages up to
            N300,000 (Three hundred thousand naira) only
          </li>
          <li>
            Extended Insurance : For a fee of 5% of the total declared value,
            damages will be covered up to N1,000,000 only. (Please note this
            Insurance option only applies to items valued at N300,000 and above)
          </li>
          <li>
            Please note all claims requests must be submitted in writing to
            Topship within three (3) working days from the date of
            damaged/missing discovery. Claims are limited to one claim per
            shipment, and settlement of which will be a full and final
            settlement for all loss or damage in connection therewith.
          </li>
          <li>
            Topship will not be liable for incorrectly declared value,
            improperly packaged items, under declared weight that may cause the
            delay/damage or confiscation of the packages/items/goods. Shipment
            insurance does not cover indirect loss or damage, or loss or damage
            caused by delays outside of Topship’s control. These include but are
            not limited to electrical or magnetic damage to, or erasure
            of,electronic or photographic images, data or recordings; any defect
            or characteristic related to the nature of the Shipment, even if
            known to Topship; any act or omission by a person not employed or
            contracted by topship - e.g. Shipper, Receiver, third party, customs
            or other government officials; "Force Majeure" - e.g. earthquake,
            cyclone, storm, flood, fog, war, plane crash, embargo, riot, civil
            commotion, or industrial action.
          </li>
        </ul>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          19.
          <span>Delayed Deliveries</span>
        </h3>
        <p>
          Pickups may be delayed for any of the following reasons: (i) Inability
          to reach the pickup contact person via phone (ii) Lack of
          communication between the merchant/account owner and the pickup
          contact (iii) Lack of a detailed pickup address (iv) Bad weather
          conditions (v) Booking received after cut off time.
        </p>
        <p>
          Users are to correctly label each package with the applicable
          T-tracking number in order to ensure proper processing. Topship will
          not be liable for any missing packages or mixed-up packages for
          delivery due to lack of proper labeling.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          20.
          <span>Amending/Modifying Shipping Information </span>
        </h3>
        <p>
          An update or amendment to an already existing booking can be initiated
          by the account owner via email (hello@topship.africa) or calling our
          customer service line (02013302594), provided the shipment is yet to
          leave its country of origin.
        </p>
        <p>
          Please note that any changes made for shipments in transit will
          attract a minimum of N2,000 supplementary charge liable to be paid by
          the customer before the changes can be effected.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          21.
          <span>Taxes and Customs Charges</span>
        </h3>
        <p>
          You acknowledge and agree that all fees, charges and any other rates
          or amounts charged by Topship to you are subject to applicable taxes
          including value added taxes.
        </p>
        <p>
          Customs duties are levied on certain goods purchased abroad. Every
          shipment that leaves Nigeria has to go through an import procedure
          determined by Customs law in the destination country. Value added
          taxes (VAT) are usually levied in the country where the purchased
          goods are consumed. Duties and taxes differ per country. Depending on
          the country, duties and taxes are settled with the customs office. You
          can find more information about customs on the website of the local
          customs authority.
        </p>
        <p>
          Please note that as a shipping service provider, Topship has no
          control over whether or not customs duties will apply, and if they do,
          how much will be charged. Topship is not responsible for any custom
          fee charges.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          22.
          <span>Cancellation of Shipments</span>
        </h3>
        <p>
          Shipments can only be canceled effectively before it leaves the
          country of origin, once shipment is in transit, a fee to return the
          package will have to be paid in order to effect cancellation.
        </p>
        <p>
          Shipments can be canceled within 14 days of payment only If the
          shipment is yet to leave its country of origin. Once a shipment is in
          transit, any cancellation request would warrant a return. Please note
          return fees would apply fully.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          23.
          <span>Third Party Services</span>
        </h3>
        <p>
          Topship is the easiest way for African businesses to export and import
          cargo, freight and parcels to their suppliers, distributors and
          customers at any location worldwide.
          <br /> We are positioned as a technology platform that provides full
          featured logistics solutions for various businesses, and as a result,
          we collaborate with other courier partners (third party services) to
          present the best possible solutions to our customers.
        </p>
        <p>
          In no circumstances will Topship Limited be liable for services
          provided by courier partners. Topship is not liable for any damage,
          destruction, or loss of Shipment, late delivery, and issues with the
          tracking, acts, errors, or omissions (including, without limitation,
          any negligence or willful misconduct) of any third-party service
          providers (including, without limitation, any delay, wrong, or missed
          pickup by the Courier). Exceptions with Insurance (See Insurance
          Clause) Shipper agrees to all routing and diversion, including the
          possibility that the Shipment may be carried via intermediate stopping
          places.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          24.
          <span>Termination</span>
        </h3>
        <p>
          We may terminate your access to all or any part of our services at any
          time, with or without cause, with or without notice, effective
          immediately, which may result in the forfeiture and destruction of all
          information associated with your use of our service. If you wish to
          terminate your account, you may do so by deleting the application from
          your device. All provisions of these Terms which by their nature
          should survive termination shall survive termination, including,
          without limitation, Intellectual Property, Warranty Disclaimers,
          Indemnity, Limitations of Liability, and Governing Law.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          25.
          <span>Governing Law</span>
        </h3>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of The Federal Republic of Nigeria, without regard to its
          conflict of law provisions.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          26.
          <span>Dispute Resolution</span>
        </h3>
        <p>
          Any disputes arising under or in connection with the validity,
          interpretation and performance of this Terms between Topship Limited
          and its customers or third parties that cannot be resolved amicably by
          the parties through negotiation within 30 (thirty) days shall be
          resolved by Arbitration at the Lagos Court of Arbitration (LCA) before
          a single arbitrator in accordance with the Arbitration and
          Conciliation Act, Cap A18, Laws of the Federation of Nigeria.
        </p>
        <p>
          The Parties shall endeavor in good faith to mutually agree on the
          selection of an arbitrator. If the Parties cannot mutually agree on
          the selection of an arbitrator within ten (10) days of the request,
          they shall apply to the LCA to appoint an arbitrator. Arbitration
          proceedings shall be conducted in Lagos. The arbitrator will be
          requested to render an award within ninety (90) days and to provide,
          in writing, the reasoning for the award. The decision of any such
          arbitrator shall be final and binding on the parties.
          <br /> Each party shall bear its cost in connection with the
          Arbitration and the arbitrator’s fees shall be split equally between
          both parties.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          27.
          <span>Changes to Terms & Conditions</span>
        </h3>
        <p>
          We reserve the right, in our sole discretion, to modify or replace any
          of these Terms, or change, suspend, or discontinue the Services
          (including without limitation, the availability of any feature,
          database, or content) at any time by posting a notice on the
          Application or by sending you notice through the Services, via email
          or by another appropriate means of electronic communication. We may
          also impose limits on certain features and services or restrict your
          access to parts or all of the Services without notice or liability.
          <br />
          While we will timely provide notice of modifications, it is also your
          responsibility to check these Terms periodically for changes. Your
          continued use of the Services following notification of any changes to
          these Terms constitutes acceptance of those changes, which will apply
          to your continued use of the Services going forward. Your use of the
          Services is subject to the Terms in effect at the time of such use.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          28.
          <span>Force Majeure</span>
        </h3>
        <p>
          We shall not be liable for any failure to perform our obligations
          hereunder where such failure results from any cause beyond our
          reasonable control, including, without limitation, mechanical,
          electronic or communications failure or degradation.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          29.
          <span>Severability</span>
        </h3>
        <p>
          These Terms are the entire Agreement between you and us with respect
          to our services, including use of our applications, and supersedes all
          prior or contemporaneous communications and proposals (whether oral,
          written or electronic) between you and us with respect to the
          Services.
          <br />
          If any provision of these Terms is found to be unenforceable or
          invalid, that provision will be limited or eliminated to the minimum
          extent necessary so that these Terms will otherwise remain in full
          force and effect and enforceable. The failure of either party to
          exercise in any respect any right provided for herein shall not be
          deemed a waiver of any further rights hereunder. The invalidity or
          unenforceability of any provision shall not affect any other part of
          these Term and Conditions.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          30.
          <span>Intellectual Property</span>
        </h3>
        <p>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of Topship Limited. Our
          trademarks may not be used in connection with any product or service
          without the prior written consent of Topship.
        </p>
        <p>
          Topship may, at its sole discretion, enable merchants to (i) create,
          upload, post, send, receive and store content, such as text, photos,
          audio, video, or other materials and information on or through the
          application ("User Content"); and (ii) access and view User Content
          and any content that Topship itself makes available on or through the
          application, including proprietary Topship content and any content
          licensed or authorized for use by or through Topship from a third
          party ("Topship Content" and together with User Content, "Collective
          Content").
        </p>
        <p>
          You represent that all content provided by you is accurate, complete,
          up-to-date, and in compliance with all applicable laws, rules and
          regulations. You retain ownership of any and all content created
          and/or uploaded by you. You acknowledge that all content, including
          User Content, accessed by you using the services is at your own risk
          and you will be solely responsible for any damage or loss to you or
          any other party resulting therefrom. We do not guarantee that any
          content you access on or through the services is or will continue to
          be accurate.
          <br />
          You will not use, copy, adapt, modify, prepare derivative works of,
          distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, broadcast or otherwise exploit the application or
          collective content, except to the extent that you are the legal owner
          of certain content or as expressly permitted in these terms. No
          licenses or rights are granted to you by implication or otherwise
          under any intellectual property rights owned or controlled by Topship
          or its licensors, except for the licenses and rights expressly granted
          in these terms.
        </p>
        <p>
          By submitting information through any of the services, you hereby do
          and shall grant us a worldwide, non-exclusive, perpetual,
          royalty-free, fully paid, sublicensable and transferable license to
          use, edit, modify, manipulate, truncate, aggregate, reproduce,
          distribute, prepare derivative works of, display, perform, and
          otherwise fully exploit the information in connection with the
          application, the services and our (and our successors’ and assigns’)
          businesses, including without limitation for promoting and
          redistributing part or all of the application or the services (and
          derivative works thereof) in any media formats and through any media
          channels (including, without limitation, third party websites and
          feeds), and including after your termination of your account or the
          services.
        </p>
        <p>
          You represent and warrant that you have all rights to grant such
          licenses to us without infringement or violation of any third-party
          rights, including without limitation, any privacy rights, publicity
          rights, copyrights, trademarks, contract rights, or any other
          intellectual property or proprietary rights.
        </p>
        <p>
          Subject to your compliance with these terms, Topship grants you a
          limited, non-exclusive, non-sub licensable, revocable,
          non-transferable license to access and view any collective content
          made available on or through the website and accessible to you, solely
          for your personal and non-commercial use. Users shall not directly or
          indirectly: (i) decipher, decompile, disassemble, reverse engineer, or
          otherwise attempt to derive any source code or underlying ideas or
          algorithms of any part of the service, except to the extent applicable
          laws specifically prohibit such restriction; (ii) modify, translate,
          or otherwise create derivative works of any part of the service; or
          (iii) copy, rent, lease, distribute, or otherwise transfer any of the
          rights that you receive hereunder.
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          31.
          <span>Anti-Fraud Indemnity</span>
        </h3>
        <p>
          Topship provides shipping services exclusively and does not operate as
          a financial institution, payments service provider, or payment
          platform. Consequently, Topship shall not be liable for any fraudulent
          payments made in connection with its shipping services
        </p>
        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          32.
          <span>Feedback</span>
        </h3>
        <p>
          We welcome and encourage you to provide feedback, comments and
          suggestions for improvements to our applications or Services.
          <br />
          You can share your feedback by filling in the form after each order,
          sending us an email, dropping a comment on any of our social media
          pages or calling in to our customer service line.
        </p>
        <p>
          Any Feedback you submit to us will be considered non-confidential and
          non-proprietary to you, and by submitting feedback to us, you grant us
          a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
          perpetual license to use and publish those ideas and materials for any
          purpose, without compensation to you.
        </p>

        <h3 className='terms-and-conditions__heading mt-2 ml-2'>
          <span>Contact Us</span>
        </h3>
        <footer className='terms-and-conditions__footer'>
          If you have any questions about these Terms, please contact us by:
          <br />
          Phone: 02013302594 <br /> Email:
          <a
            target='_blank'
            rel='noreferrer'
            href='mailto: bookings@mytopship.com'
          >
            hello@topship.africa
          </a>{' '}
          <br />
          Address: 25b, Bisola Durosinmi Etti Drive, Lekki Phase 1
        </footer>
      </div>
    </>
  );
};

export default Terms;
