import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  userState,
  shopnshipSelectedProductState
} from '../../../../../recoil/atoms';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from '../../../../../components/new-dashboard/custom-input';
import { Alert, Box, Grid, Select, MenuItem } from '@mui/material';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import { InfoDarkIcon } from '../../../../../constants/asset-contants';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import CustomAutoComplete from '../../../../../components/new-dashboard/custom-autocomplete';
import {
  useGetItemCategoriesQuery,
  useGetStatesQuery
} from '../../../../../operations/queries';
import { useGetCitiesMutation } from '../../../../../operations/mutations';
import { useFilterIntracityShipments } from '../../../../../utilities/check-intracity';
import debounce from 'lodash.debounce';
import { PackageNameCustomLabelMessage } from '../../../../../constants/shopnship-custom-label-message';
import {
  getCountryTags,
  isStandard,
  shippingFromOptions
} from '../../../../../constants/shopnship-constants';
import { SelectOptionsComponent } from './shop-n-ship-welcome';

const countryCode = 'ng';

const countryTags = getCountryTags();

const validationSchema = yup.object().shape({
  isChinaImport: yup.boolean().required(),
  packageName: yup.string().when('isChinaImport', {
    is: true,
    then: yup.string().required('Please enter package name'),
    otherwise: yup.string()
  }),
  shippingFrom: yup.string().required('Please select a location'),
  deliveryName: yup.string().required('Please enter a delivery name'),
  deliveryAddress: yup.string().required('Please enter a delivery address'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('please enter an email address'),
  phoneNumber: yup.string().required('Please enter a phone number'),
  deliveryState: yup.string().max(35).required('Please select a state'),
  deliveryCity: yup.string().max(35).required('Please select a city')
});

const ShopnshipPackageInformation = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );
  const history = useHistory();

  const [isChinaImport] = useRecoilState(chinaImportState);

  const [getStates, states] = useGetStatesQuery();

  const [getItemsCategories] = useGetItemCategoriesQuery();

  const [getCities, citiesResult] = useGetCitiesMutation();

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  // const places = useMemo(() => {
  //   return {
  //     UK: `UK Hub: ${GetShopnshipHubAddress(
  //       'UK',
  //       shopNshipDetails?.pricingTier
  //     )}`,
  //     US: `USA Hub: ${GetShopnshipHubAddress(
  //       'US',
  //       shopNshipDetails?.pricingTier
  //     )}`,
  //     CANADA: `CANADA Hub: ${GetShopnshipHubAddress(
  //       'CA',
  //       shopNshipDetails?.pricingTier
  //     )}`,
  //     CHINA: 'CHINA Hub:'
  //   };
  // }, [shopNshipDetails?.pricingTier]);

  const [selectedCountry, setSelectedCountry] = useRecoilState(
    shopnshipSelectedCountryState
  );

  const [selectedProduct, setSelectedProduct] = useRecoilState(
    shopnshipSelectedProductState
  );

  const [user] = useRecoilState(userState);

  const { filterStates } = useFilterIntracityShipments();

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return filteredLinks.findIndex(
      link => link.link === history.location.pathname
    );
  }, [history.location.pathname]);

  const [showAddressGuide, setShowAddressGuide] = useState(false);

  const tagName = useMemo(() => {
    if (isStandard(selectedCountry?.value)) {
      return selectedCountry?.origin;
    }

    return countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]] || '';
  }, [countryTags, shopNshipDetails?.shippingFrom]);

  const handleLocationSelect = place => {
    const { name, value, countryCode } = place;

    if (countryCode?.toLowerCase() === 'ng') {
      setFieldValue('postalCode', '100001');
    }

    if (countryCode) {
      setFieldValue('countryCode', countryCode);
    }

    handleChange({ target: { name, value } });
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      shippingFrom: isChinaImport ? 'CHINA' : '',
      deliveryName: user?.fullName || '',
      deliveryAddress: '',
      deliveryState: '',
      deliveryCity: '',
      isChinaImport: isChinaImport,
      packageName: ''
    },

    onSubmit: values => {
      delete values.isChinaImport;

      if (!isChinaImport) {
        setSelectedCountry(
          shippingFromOptions.find(
            option => option.value === values.shippingFrom
          )
        );
      }

      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });

      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    const keys = Object.keys(shopNshipDetails);

    const _values = Object.values(shopNshipDetails);

    const formKeys = Object.keys(values);

    _values.forEach((value, index) => {
      const formKey = formKeys.find(key => keys[index] === key);

      if (value && shopNshipDetails[keys[index]] === value && formKey) {
        setFieldValue(keys[index], value);
      }
    });

    if (isChinaImport) {
      setSaveShopNshipDetails({ ...shopNshipDetails, shippingFrom: 'CHINA' });
    }
  }, []);

  useEffect(() => {
    getStates(countryCode);

    getCities({
      countryCode: countryCode,
      cityName: shopNshipDetails.deliveryCity
    });

    getItemsCategories({
      pricingTier: 'Standard',
      shippingFrom: isChinaImport ? 'CHINA' : selectedCountry?.value,
      isChinaImport
    });

    // eslint-disable-next-line
  }, []);

  const handleProductChange = value => {
    setSelectedProduct(value);
  };

  useEffect(() => {
    if (!isChinaImport && selectedCountry?.value) {
      setFieldValue('shippingFrom', selectedCountry?.value);
    }

    // eslint-disable-next-line
  }, [isChinaImport]);

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Package Information'>
        <div className='new-shop-n-ship__modal__body'>
          <div className='new-shop-n-ship__modal__body__wrap'>
            <div className='new-shop-n-ship__modal__body__content'>
              <form
                onSubmit={handleSubmit}
                className='new-shop-n-ship__modal__body__form'
              >
                {isChinaImport ? (
                  <Grid container rowSpacing='16px' columnSpacing='16px'>
                    <Input
                      name='packageName'
                      value={values.packageName}
                      error={touched.packageName && Boolean(errors.packageName)}
                      helperText={touched.packageName && errors.packageName}
                      customLabelMessage={
                        <PackageNameCustomLabelMessage tagName={tagName} />
                      }
                      onChange={handleChange}
                      customLabel='Name on package'
                      type='text'
                      placeholder={'邓涛topship - Jane Doe'}
                      containerClassName='new-shop-n-ship__modal__body__form__item__input'
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid container rowSpacing='16px' columnSpacing='16px'>
                  {isChinaImport ? null : (
                    <SelectOptionsComponent
                      setFieldValue={setFieldValue}
                      label={'Where are you shipping from?'}
                      placeholder={'a country'}
                      selectedValue={selectedCountry}
                      setSelectedValue={setSelectedCountry}
                      optionsArray={shippingFromOptions}
                      autocomplete
                      error={errors.shippingFrom}
                    />
                  )}
                  {/* <SelectOptionsComponent
                    label={'What type of product are you purchasing?'}
                    placeholder={'product'}
                    selectedValue={selectedProduct}
                    setSelectedValue={setSelectedProduct}
                    optionsArray={productOptions}
                  /> */}
                  {/* <div className='mt-4'> */}

                  {/* <Select
                    labelId='product-select-label'
                    value={selectedProduct}
                    onChange={handleProductChange}
                    label='What type of product are you purchasing?' // Displayed in the dropdown
                  >
                    {productOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select> */}
                  {/* <SelectOptionsComponent
                    label={'What type of product are you purchasing?'}
                    placeholder={'product'}
                    selectedValue={selectedProduct}
                    setSelectedValue={setSelectedProduct}
                    optionsArray={productOptions}
                  /> */}
                  {/* </div> */}
                  {/* <CustomAutoComplete
                    autoComplete={false}
                    options={productOptions}
                    getOptionLabel={option => option?.label}
                    isOptionEqualToValue={(option, value) =>
                      option?.label
                        ? option?.label === values.label
                        : values.label
                    }
                    renderOption={(props, option) => (
                      <Box
                        key={props.id}
                        component='li'
                        sx={{
                          textTransform: 'capitalize'
                        }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                    value={productOptions.find(
                      product => product.label === values.label
                    )}
                    noOptionsText={
                      citiesResult.loading
                        ? 'Product loading...'
                        : 'Product not found'
                    }
                    onChange={(event, value) => handleProductChange(value)}
                    textfieldProps={{
                      lg: 12,
                      md: 12,
                      name: 'selectedProduct', 
                      customLabel: 'What type of product are you purchasing?',
                      containerClassName:
                        'new-shop-n-ship__modal__body__form__item__input'
                    }}
                  /> */}
                  <Input
                    name='deliveryName'
                    value={values.deliveryName}
                    error={touched.deliveryName && Boolean(errors.deliveryName)}
                    helperText={touched.deliveryName && errors.deliveryName}
                    onChange={handleChange}
                    customLabel="Receiver's Full Name"
                    type='text'
                    placeholder='Uchenna Topship'
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                  />
                  <Input
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    value={values.email}
                    onChange={handleChange}
                    customLabel="Receiver's Email Address"
                    name='email'
                    id='email'
                    type='email'
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    placeholder='garrick@topship.com'
                    fullWidth
                    lg={6}
                    md={12}
                  />
                  <Input
                    id='phoneNumber'
                    name='phoneNumber'
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber}
                    onChange={(value, code) => {
                      setFieldValue('phoneNumber', value);
                    }}
                    defaultCountry={countryCode}
                    value={values.phoneNumber}
                    type='tel'
                    placeholder='+234 801 234 5678'
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    customLabel="Receiver's Phone Number"
                    lg={6}
                    md={12}
                  />
                  <Input
                    name='deliveryAddress'
                    value={values.deliveryAddress}
                    error={
                      touched.deliveryAddress && Boolean(errors.deliveryAddress)
                    }
                    helperText={
                      touched.deliveryAddress && errors.deliveryAddress
                    }
                    onChange={handleChange}
                    customLabel={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <p className='mb-0'>Receiver's Address</p>
                        <img
                          title='Click to see address guide'
                          onClick={() => setShowAddressGuide(true)}
                          style={{
                            width: '20px',
                            paddingLeft: '6px',
                            cursor: 'pointer'
                          }}
                          src={InfoDarkIcon}
                          alt=''
                        />
                      </div>
                    }
                    customLabelMessage='Enter the address in Nigeria where you want this item to be delivered'
                    type='text'
                    placeholder='Oando Petrol Station, Iweka Road'
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                  />
                  <CustomAutoComplete
                    options={
                      states.data
                        ? states.data?.getStates?.filter(filterStates)
                        : []
                    }
                    getOptionLabel={option => option?.name}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {option.name}
                      </Box>
                    )}
                    value={
                      states?.data
                        ? states?.data?.getStates?.find(
                            state => state.name === values.deliveryState // Change 'state' to 'deliveryState'
                          )
                        : { name: '' }
                    }
                    noOptionsText={'State not found'}
                    onChange={(event, value) =>
                      handleLocationSelect({
                        name: 'deliveryState', // Change 'state' to 'deliveryState'
                        value: value?.name
                      })
                    }
                    textfieldProps={{
                      lg: 6,
                      md: 12,
                      name: 'deliveryState', // Change 'state' to 'deliveryState'
                      customLabel: 'State',
                      containerClassName:
                        'new-shop-n-ship__modal__body__form__item__input',
                      error: Boolean(errors.deliveryState), // Change 'state' to 'deliveryState'
                      helperText: errors.deliveryState, // Change 'state' to 'deliveryState'
                      value: values.deliveryState // Change 'state' to 'deliveryState'
                    }}
                  />
                  <CustomAutoComplete
                    options={citiesResult.data?.length ? citiesResult.data : []}
                    getOptionLabel={option =>
                      option?.city
                        ?.split(' ')
                        ?.map(
                          word =>
                            word &&
                            `${word?.charAt()?.toUpperCase()}${word
                              .substring(1, word.length)
                              ?.toLowerCase()}`
                        )
                        ?.join(' ')
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.postalCode
                        ? option?.postalCode === values.postalCode &&
                          option?.city === value?.city
                        : option?.city === value?.city
                    }
                    renderOption={(props, option) => (
                      <Box
                        key={props.id}
                        component='li'
                        sx={{
                          textTransform: 'capitalize'
                        }}
                        {...props}
                      >
                        {`${option?.city?.toLowerCase()}${
                          option?.postalCode ? ` (${option?.postalCode})` : ''
                        }`}
                      </Box>
                    )}
                    value={citiesResult.data?.find(
                      city => city.city === values.deliveryCity // Change 'city' to 'deliveryCity'
                    )}
                    noOptionsText={
                      citiesResult.loading
                        ? 'Cities loading...'
                        : 'City not found'
                    }
                    onChange={(event, value) =>
                      handleLocationSelect({
                        name: 'deliveryCity', // Change 'city' to 'deliveryCity'
                        value: value?.city
                      })
                    }
                    textfieldProps={{
                      lg: 6,
                      md: 12,
                      name: 'deliveryCity', // Change 'city' to 'deliveryCity'
                      customLabel: 'City',
                      containerClassName:
                        'new-shop-n-ship__modal__body__form__item__input',
                      error: Boolean(errors.deliveryCity), // Change 'city' to 'deliveryCity'
                      helperText: errors.deliveryCity, // Change 'city' to 'deliveryCity'
                      value: values.deliveryCity, // Change 'city' to 'deliveryCity'
                      onChange: debounce(
                        event =>
                          getCities({
                            countryCode: countryCode,
                            cityName: event?.target?.value
                          }),
                        500
                      )
                    }}
                  />
                </Grid>
                {isChinaImport ? (
                  <Alert
                    severity={'info'}
                    sx={{
                      marginTop: '10px',
                      fontSize: '1.5rem',
                      fontWeight: 'medium',
                      fontFamily: 'General Sans',
                      backgroundColor: '#E1F9FF',
                      color: '#416EB2',
                      border: 'none',
                      '& .MuiAlert-icon': {
                        fontSize: '1.6rem',
                        marginRight: '5px',
                        marginTop: '2px',
                        color: '#416EB2'
                      }
                    }}
                  >
                    Please note that doorstep delivery would come at an extra
                    cost to the receiver
                  </Alert>
                ) : null}
                <div className='new-shop-n-ship__modal__body__form__item__buttons'>
                  <div
                    className='new-shop-n-ship__modal__body__form__item__button'
                    onClick={() =>
                      history.push(filteredLinks[currentIndex - 1].link)
                    }
                  >
                    Go Back
                  </div>
                  <SubmitButton text='Continue' />
                </div>
              </form>
            </div>
          </div>
        </div>
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
    </>
  );
};

export default ShopnshipPackageInformation;
